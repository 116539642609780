body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
	font-size: 1.6rem;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	line-height: 1.25;
}

.iris-content {
	color: $colorPlatformGray800;

	/* stylelint-disable-next-line no-invalid-position-at-import-rule */
	@import 'standardize';

	/* -- Opt-in Styles */

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6 {
		margin-bottom: 2rem;
		margin-top: 1rem;
	}


	blockquote {
		margin: 1rem 2rem 2rem;
	}

	dd {
		display: block;
		margin-left: 4rem;
	}

	dl {
		margin-bottom: 2rem;
		margin-top: 1rem;
	}

	hr {
		display: block;
		margin-bottom: 2rem;
		margin-top: 1rem;
		width: 100%;
	}

	li {
		list-style: unset;
	}

	ol {
		list-style: decimal;
	}

	p {
		margin-bottom: 2rem;
		margin-top: 1rem;
	}

	pre {
		margin: 1rem 0 2rem;
	}

	table {
		border: 0;
		border-collapse: collapse;
		border-spacing: 0;
		table-layout: fixed;
		width: 100%;

		th,
		td {
			border: 0;
		}

		th {
			border-bottom: $colorPlatformGray100 solid 2px;
			color: $colorPlatformGray500;
			font-size: 1.2rem;
			font-weight: 600;
			padding: 1rem 2rem;
			text-align: left;
			text-transform: uppercase;
		}

		td {
			border-bottom: $colorPlatformGray100 solid 1px;
			padding: 2rem;
		}

		caption {
			font-size: 1.2rem;
			text-align: center;
		}
	}

	ul,
	ol {
		margin-bottom: 2rem;
		margin-top: 1rem;
		padding-left: 4rem;
	}

	ul {
		list-style: disc;
	}

	img {
		max-width: 100%;
	}
}
