
.iris-tile {
	border: transparent 1px solid;
	border-radius: 3px;
	padding: 1rem;
}

.iris-tile--shaded {
	background-color: $colorPlatformGray50;
	border-color: $colorPlatformGray100;
}
