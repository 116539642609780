// Component variables
// =============================================================================
// Background variables
$notification-background-negative: $colorBrandedError;
$notification-background-positive: $colorBrandedSuccess;
$notification-background-caution: $colorBrandedCaution;
$notification-background-info: $colorBrandedInfo;
$notification-background-bulletin: $colorBrandedBulletin;
$notification-background-content: $colorPlatformWhite; // Border variables
$notification-border-default: 1px solid;
$notification-border-negative: $colorBrandedError;
$notification-border-positive: $colorBrandedSuccess;
$notification-border-caution: $colorBrandedCaution;
$notification-border-info: $colorBrandedInfo;
$notification-border-bulletin: $colorBrandedBulletin; // Text variables
$notification-font-size-breadbox: 1.4rem;
$notification-line-height-breadbox: 1.6rem;
$notification-icon-size-breadbox: 1.6rem;
$notification-text-breadbox: $colorPlatformGray800;
$notification-icon-breadbox: $colorPlatformWhite;
$notification-font-size-baguette: 1.6rem;
$notification-font-size-baguette-mobile: 1.4rem;
$notification-line-height-baguette: 1.8rem;
$notification-icon-size-baguette: 3.8rem;
$notification-icon-size-baguette-mobile: 2rem;
$notification-text-baguette: $colorPlatformWhite;
$notification-icon-baguette: $colorPlatformWhite;
$notification-text-bulletin: $colorPlatformGray800;
$notification-icon-bulletin: $colorPlatformGray800; // Padding variables
$notification-padding-breadbox-message: 1rem 1.5rem;
$notification-padding-breadbox-action: 1rem 1.5rem 1rem 0; // Example HTML markup
// =============================================================================
// Breadbox
// <div class="iris-notification iris-notification--breadbox iris-notification--negative">
//     <div class="iris-notification__context">
//        <span class="font-icon-warning"></span>
//     </div>
//     <p class="iris-notification__message">Something went wrong</p>
// </div>
//
// Baguette
// <div class="iris-notification iris-notification--baguette iris-notification--negative">
//     <div class="iris-notification__inner">
//         <div class="iris-notification__context">
//             <span class="font-icon-warning"></span>
//         </div>
//         <p class="iris-notification__message">Something went wrong!</p>
//         <button class="iris-button iris-button--ghost iris-notification__action-button" aria-label="Close Notification">
//             <span class="iris-button__icon font-icon-close"></span>
//         </button>
//     </div>
// </div>
// Base component styles
// =============================================================================

.iris-notification {
	.iris-notification__context {
		align-items: center;
		display: flex;
		flex-grow: 0;
		flex-shrink: 0;
		justify-content: center;
	}
}

// Variants:

.iris-notification {
	// Variant: Breadbox
	&--breadbox {
		background-color: $notification-background-content;
		border: $notification-border-default;
		border-radius: 3px;
		display: flex;
		width: 100%;

		.iris-notification__context {
			color: $notification-icon-breadbox;
			flex-shrink: 0;
			font-size: $notification-icon-size-breadbox;
			padding: 2.2rem 1.2rem;

			[class*='font-icon-']::before,
			[class*='font-icon-']::after {
				font-size: $notification-icon-size-breadbox;
			}
		}

		.iris-notification__message {
			color: $notification-text-breadbox;
			display: flex;
			flex-direction: column;
			flex-grow: 2;
			flex-shrink: 2;
			font-size: $notification-font-size-breadbox;
			justify-content: center;
			line-height: $notification-line-height-breadbox;
			margin: 0;
			padding: $notification-padding-breadbox-message;
			width: 100%;
		}

		.iris-notification__action {
			align-self: flex-start;
			flex-shrink: 0;
			padding: $notification-padding-breadbox-action;
		}
	}

	// Variant: Baguette
	&--baguette {
		width: 100%;

		.iris-notification {
			align-items: center;
			display: flex;
			justify-content: center;
		}

		.iris-notification__inner {
			align-items: center;
			display: flex;
			margin: 0 auto;
			max-width: 932px;
			padding: 30px;
			width: 100%;
		}

		.iris-notification__context {
			color: $notification-icon-baguette;
			font-size: $notification-icon-size-baguette;
			margin-right: 14px;

			[class*='font-icon-']::before,
			[class*='font-icon-']::after {
				font-size: $notification-icon-size-baguette;
			}
		}

		.iris-notification__message {
			color: $notification-text-baguette;
			font-size: $notification-font-size-baguette;
			line-height: $notification-line-height-baguette;
		}

		.iris-notification__action-button {
			color: $notification-text-baguette;
			font-size: 1.8rem;
			line-height: 1.8rem;
			margin-left: auto;
			padding: 18px 20px;

			&:hover {
				color: darken($notification-text-baguette, 10%);
			}

			&:focus {
				background-color: $notification-text-baguette;
				color: $colorPlatformGray800;
			}
		}
	}
}

// States:
// =============================================================================

.iris-notification {
	// State: Negative
	&--negative {
		// Variant breadbox is negative
		&.iris-notification--breadbox {
			border-color: $notification-border-negative;

			.iris-notification__context {
				background-color: $notification-background-negative;
			}
		}

		// Variant baguette is negative
		&.iris-notification--baguette {
			background-color: $notification-background-negative;
		}
	}

	// State: Positive
	&--positive {
		// Variant breadbox is positive
		&.iris-notification--breadbox {
			border-color: $notification-border-positive;

			.iris-notification__context {
				background-color: $notification-background-positive;
			}
		}

		// Variant baguette is positive
		&.iris-notification--baguette {
			background-color: $notification-background-positive;
		}
	}

	// State: Caution
	&--caution {
		// Variant breadbox is caution
		&.iris-notification--breadbox {
			border-color: $notification-border-caution;

			.iris-notification__context {
				background-color: $notification-background-caution;
			}
		}

		// Variant baguette is caution
		&.iris-notification--baguette {
			background-color: $notification-background-caution;
		}
	}

	// State: Info
	&--info {
		// Variant breadbox is info
		&.iris-notification--breadbox {
			border-color: $notification-border-info;

			.iris-notification__context {
				background-color: $notification-background-info;
			}
		}

		// Variant baguette is info
		&.iris-notification--baguette {
			background-color: $notification-background-info;
		}
	}

	// State: Bulletin
	&--bulletin {
		--colorBrandedBulletin: #FFECB5;

		// Variant breadbox is bulletin
		&.iris-notification--breadbox {
			border-color: $notification-border-bulletin;
			border-color: var(--colorBrandedBulletin);

			.iris-notification__context {
				background-color: $notification-background-bulletin;
				background-color: var(--colorBrandedBulletin);
				color: $notification-icon-bulletin;
			}
		}

		// Variant baguette is bulletin
		&.iris-notification--baguette {
			background-color: $notification-background-bulletin;
			background-color: var(--colorBrandedBulletin);

			.iris-notification__context {
				color: $notification-icon-bulletin;
			}

			.iris-notification__action-button,
			.iris-notification__message {
				color: $notification-text-bulletin;
			}

			.iris-notification__action-button:hover {
				color: darken($notification-text-bulletin, 10%);
			}
		}
	}
}

// Decorators
// =============================================================================

.iris-notification {
	&[data-modifier='stacked'] {
		.iris-notification__inner {
			flex-direction: column;
			position: relative;
		}

		.iris-notification__context {
			margin-right: 0;
		}

		.iris-notification__action-button {
			position: absolute;
			right: 10px;
			top: 10px;
		}
	}
}

// Media Queries
// =============================================================================
@include breakpoint-max(sm) {
	.iris-notification {
		.iris-notification__message {
			font-size: $notification-font-size-baguette-mobile;
			margin: 0;
		}

		&--breadbox {
			.iris-notification__context {
				padding: 1.2rem 1rem;
			}

			.iris-notification__message {
				padding: 1.2rem 1rem;
			}
		}

		&--baguette {
			.iris-notification__inner {
				padding: 10px;
			}

			.iris-notification__context {
				[class*='font-icon-']::before,
				[class*='font-icon-']::after {
					font-size: $notification-icon-size-baguette-mobile;
				}
			}

			.iris-notification__action-button {
				border: 0;
				padding: 0;
			}
		}
	}
}
