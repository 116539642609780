// Variables
// =============================================================================

// Background colors
$badge-background-positive: $colorBrandedSuccess !default;
$badge-background-negative: $colorBrandedError !default;
$badge-background-neutral: $colorPlatformGray500 !default;
$badge-background-info: $colorBrandedInfo !default;
$badge-background-polite: $colorPlatformGray50 !default;
$badge-background-caution: $colorBrandedCaution !default;


// Border colors
$badge-border-info: $colorBrandedInfo !default;
$badge-border-circular: $colorPlatformWhite !default;

// Font colors
$badge-font-color-light: $colorPlatformWhite !default;
$badge-font-color-dark: $colorPlatformGray500 !default;

// Box Shadow
$badge-box-shadow-hover: 0 0 4px 0 $colorBrandedInfo !default;


// Example HTML markup
// =============================================================================
// <div class="iris-badge iris-badge--squared">
//     <div class="iris-badge__content">Badge Content</div>
// </div>


// Base styling
// =============================================================================
.iris-badge {
	align-items: center;
	display: inline-flex;
	font-size: 1.2rem;
	font-weight: 600;
	justify-content: center;
	line-height: 1.2rem;
	text-transform: uppercase;
	transition: 200ms all linear;
}


// Variants
// =============================================================================
.iris-badge {
	&--squared {
		background-color: $badge-background-neutral;
		border-radius: 2px;
		color: $badge-font-color-light;
		padding: 3px 8px;
	}

	&--rounded {
		border-radius: 1.5rem;
		padding: 3px 8px;
	}

	&--circular {
		border-color: $badge-border-circular;
		border-radius: 2rem;
		border-style: solid;
		border-width: 2px;
		line-height: 1;
		padding: 3px;

		&:empty {
			padding: 4px;
		}
	}
}


// States
// =============================================================================
.iris-badge {
	&.iris-badge--positive {
		background-color: $badge-background-positive;
		color: $badge-font-color-light;
	}

	&.iris-badge--negative {
		background-color: $badge-background-negative;
		color: $badge-font-color-light;
	}

	&.iris-badge--neutral {
		background-color: $badge-background-neutral;
		color: $badge-font-color-light;
	}

	&.iris-badge--info {
		background-color: $badge-background-info;
		color: $badge-font-color-light;
	}

	&.iris-badge--polite {
		background-color: $badge-background-polite;
		color: $badge-font-color-dark;
	}

	&.iris-badge--caution {
		background-color: $badge-background-caution;
		color: $badge-font-color-light;
	}
}


// Decorators
// =============================================================================
.iris-badge {
	&[data-position='top-left'] {
		position: absolute;

		&:not(.iris-badge--circular),
		&.iris-badge--circular:empty {
			left: 0;
			top: 0;
		}

		&.iris-badge--squared {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		&.iris-badge--circular:not(:empty) {
			left: -5px;
			top: -5px;
		}
	}

	&[data-position='top-right'] {
		position: absolute;

		&:not(.iris-badge--circular),
		&.iris-badge--circular:empty {
			right: 0;
			top: 0;
		}

		&.iris-badge--squared {
			border-bottom-right-radius: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		&.iris-badge--circular:not(:empty) {
			right: -5px;
			top: -5px;
		}
	}

	&[data-position='bottom-left'] {
		position: absolute;

		&:not(.iris-badge--circular),
		&.iris-badge--circular:empty {
			bottom: 0;
			left: 0;
		}

		&.iris-badge--squared {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border-top-left-radius: 0;
		}

		&.iris-badge--circular:not(:empty) {
			bottom: -5px;
			left: -5px;
		}
	}

	&[data-position='bottom-right'] {
		position: absolute;

		&:not(.iris-badge--circular),
		&.iris-badge--circular:empty {
			bottom: 0;
			right: 0;
		}

		&.iris-badge--squared {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
		}

		&.iris-badge--circular:not(:empty) {
			bottom: -5px;
			right: -5px;
		}
	}
}
