// Component variables
// =============================================================================

// Font variables
$empty-state-title-color: $colorPlatformGray800 !default;
$empty-state-copy-color: $colorPlatformGray500 !default;

// Example HTML markup
// =============================================================================
// <div class="iris-empty-state">
//     <div class="iris-empty-state__inner">
//         <h3 class="iris-empty-state__title">No Assets</h3>
//         <p class="iris-empty-state__copy">Get an accurate picture of your net worth by tracking your property, collectables or anything else of value.</p>
//         <div class="iris-empty-state__actions">
//             <button class="iris-button iris-button--secondary">
//                 <span class="iris-button__icon font-icon-add mar-right--sm"></span>
//                 Add an Asset
//             </button>
//         </div>
//     </div>
// </div>

// Base component styling
// =============================================================================

.iris-empty-state {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    width: 100%;
}

.iris-empty-state__inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    text-align: center;
    width: 100%;
}

.iris-empty-state__title {
    color: $empty-state-title-color;
    margin-bottom: 10px;
    margin-top: 0;
}

.iris-empty-state__copy {
    color: $empty-state-copy-color;
    margin-bottom: 40px;
    margin-top: 0;
}
