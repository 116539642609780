// Variables
// =============================================================================

// Background variables
$card-background-color: $colorPlatformWhite;

// Border Variables
$card-border-default: $colorPlatformGray100;
$card-border-draggable: $colorPlatformGray500;
$card-border-radius: 5px;

// We need to subtract 1px for the border radius to match up properly.
$card-badge-border-radius: $card-border-radius - 1px;

// Box shadow variables
$card-box-shadow-default: 0 10px 20px -5px rgb(0 0 0 / 30%);
$card-box-shadow-hover: 0 15px 20px -5px rgb(0 0 0 / 30%);

// Text color variables
$card-text-color-default: $colorPlatformGray500;

// Margin variables
$card-margin-default: 20px;
$card-margin-image: 0;
$card-margin-content: 20px;

// Width variables
$card-width-small: $container-width-small;
$card-width-medium: $container-width-medium;
$card-width-large: $container-width-large;


// Example HTML markup
// =============================================================================
// <div class="iris-card">
//		<div class="iris-card__header"><h4>Card Header</h4></div>
//	    <div class="iris-card__content">Card Content</div>
//		<div class="iris-card__footer">Card Footer</div>
// </div>


// Base styling
// =============================================================================
.iris-card {
	background-color: $card-background-color;
	border: 1px solid $card-border-default;
	border-radius: $card-border-radius;
	transition: 200ms all linear;
}

.iris-card__header {
	margin: $card-margin-default;
}

.iris-card__title {
	margin: 0;
}

.iris-card__content {
	color: $card-text-color-default;
	margin: $card-margin-content;
}

.iris-card__image {
	display: block;
	object-fit: cover;
	object-position: center;
	width: 100%;
}

.iris-card__overlay {
	align-items: center;
	display: flex;
	inset: 0;
	justify-content: center;
	position: absolute;
}

.iris-card__footer {
	align-items: center;
	display: flex;
	margin: $card-margin-default;
}


// Variants
// =============================================================================
.iris-card {
	&--flat {
		box-shadow: none;
	}

	&--shadow {
		box-shadow: $card-box-shadow-default;
	}

	&--image {
		overflow: hidden;

		.iris-card__content {
			margin: $card-margin-image;
			position: relative;
		}
	}
}


// State
// =============================================================================
.iris-card {
	&--draggable {
		border-color: $card-border-draggable;
		border-style: dashed;
		border-width: 2px;
		cursor: move;
	}
}


// Decorators
// =============================================================================
.iris-card {
	&[data-size='small'] {
		width: $card-width-small;
	}

	&[data-size='medium'] {
		width: $card-width-medium;
	}

	&[data-size='large'] {
		width: $card-width-large;
	}

	&[data-size='full-width'] {
		width: 100%;
	}

	&[data-hoverable] {
		&.iris-card--shadow:hover {
			box-shadow: $card-box-shadow-hover;
			transform: translateY(-10px);
		}
	}

	// Using progressively enhanced focus styles here. There's no support for :focus-within in IE or Edge.
	// Keeping this block separate from the :hover style or else IE will ignore both the :focus-within and :hover style
	&[data-hoverable] {
		&.iris-card--shadow:focus-within {
			box-shadow: $card-box-shadow-hover;
			transform: translateY(-10px);
		}
	}
}

// HACK (kind of): This is technically badge specific code, but uses variables
// from the card component since we need to match the border radius of the card.
// By having it here, we can ensure the border radius will always match.
.iris-card .iris-badge--squared {
	&[data-position='top-left'] {
		border-bottom-right-radius: $card-badge-border-radius;
		border-top-left-radius: $card-badge-border-radius;
	}

	&[data-position='top-right'] {
		border-bottom-left-radius: $card-badge-border-radius;
		border-top-right-radius: $card-badge-border-radius;
	}

	&[data-position='bottom-left'] {
		border-bottom-left-radius: $card-badge-border-radius;
		border-top-right-radius: $card-badge-border-radius;
	}

	&[data-position='bottom-right'] {
		border-bottom-right-radius: $card-badge-border-radius;
		border-top-left-radius: $card-badge-border-radius;
	}
}
