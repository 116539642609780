
// Desktop Specific Styling
// =============================================================================
@mixin dropdownDesktopStyling {
	.iris-dropdown {
		.iris-dropdown__display-list-header {
			display: none;
		}

		.iris-dropdown__display-list-container {
			background: $colorPlatformWhite;
			border: $dropdown-border;
			border-radius: 3px;
			left: 0;
			max-height: 250px;
			min-width: 100%;
			opacity: 0;
			overflow-y: auto;
			position: absolute;
			top: 100%;
			transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
			z-index: 1;
		}

		&.iris-dropdown--expanding,
		&.iris-dropdown--collapsing {
			& .iris-dropdown__display-list-container {
				max-height: 0;
				opacity: 0;
			}
		}

		&.iris-dropdown--expanded {
			& .iris-dropdown__display-list-container {
				max-height: 250px;
				opacity: 1;
			}
		}

		&--dropup {
			.iris-dropdown__display-list-container {
				bottom: 100%;
				top: auto;
			}
		}
	}
}
