
// Variables
// =============================================================================

// Background
$list-item-selected: $colorPlatformGray50 !default;
$list-item-active: $colorPlatformGray50 !default;

// Border
$list-item-selected-border: $colorPlatformGray100 !default;
$list-item-active-border: $colorPlatformGray100 !default;

// Box Shadow
$list-item-box-shadow-hover: 0 8px 15px -7px rgb(0 0 0 / 60%);

// Base Styles
// =============================================================================
.iris-list {
	background: $colorPlatformWhite;
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;

	.iris-list-item {
		.iris-account-tile {
			margin: 0.5rem;
			opacity: 0.75;
		}

		.iris-tile {
			margin: 0.5rem;
		}
	}
}

.iris-list {
	&--actionable,
	&--checkable {
		.iris-list-item {
			cursor: pointer;

			&:hover {
				.iris-record,
				.iris-tile,
				.iris-list-item__content {
					background: $list-item-active;
					border-color: $list-item-active-border;
				}

				.iris-account-tile {
					box-shadow: $list-item-box-shadow-hover;
					opacity: 1;
				}
			}

			&[disabled] {
				.iris-account-tile,
				.iris-record,
				.iris-tile,
				.iris-list-item__content {
					opacity: 0.5;
				}
			}

			.iris-checkbox {
				pointer-events: none;
			}
		}
	}

	&--actionable {
		.iris-list-item {
			.iris-account-tile::after {
				transition: 250ms cubic-bezier(0.5, 0, 0.2, 1);
			}

			&[aria-selected='true'] {
				.iris-record,
				.iris-tile,
				.iris-list-item__content {
					background: $list-item-selected;
					border-color: $list-item-selected-border;
				}

				.iris-account-tile::after {
					transform: translate3d(100%, 0, 0) skew(45deg, 0);
				}
			}
		}
	}

	// Fixes issue with active choice being visible for checkable lists when mouse is being used.
	&--checkable {
		.iris-list-item {
			.iris-keyboard-use-detected &[data-active='true'] {
				.iris-record,
				.iris-tile,
				.iris-list-item__content {
					background: $list-item-active;
					border-color: $list-item-active-border;
				}
			}
		}
	}

	// Optimizes the keyboard only styles
	.iris-keyboard-use-detected & {
		.iris-list-item {
			&[data-active='true']:not([aria-selected='true']) {
				.iris-record,
				.iris-tile,
				.iris-list-item__content {
					background: $list-item-active;
					border-color: $list-item-active-border;
				}

				.iris-account-tile {
					box-shadow: $list-item-box-shadow-hover;
					opacity: 1;
				}
			}
		}
	}
}
