// Example Markup
// =============================================================================

// Text variables
$form-label-text: $colorPlatformGray500 !default;
$form-note-text: $colorPlatformGray500 !default;
$form-label-font-size: 1.4rem !default;
$form-note-font-size: 1.2rem !default;

// Example Markup
// =============================================================================
// <div class="iris-form-group iris-form-group--inline">
// 	<label class="iris-form-label__label" for="frm_textfield_5">My Label</label>
// 	<div class="iris-form-inputs">
// 		<div class="iris-textfield">
// 			<input id="frm_textfield_5" type="text" class="iris-textfield__input" placeholder="Default" />
// 		</div>
// 	</div>
// </div>


// Base Styling
// =============================================================================
.iris-form-group {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;

	& > .iris-form-label,
	& > .iris-form-group__label {
		margin: 0;
		padding-bottom: 10px;
	}
}

.iris-form-group__label,
.iris-form-label,
.iris-label {
	color: $form-label-text;
	font-size: $form-label-font-size;
	font-weight: 600;
}

.iris-form-group__note,
.iris-form-note {
	color: $form-note-text;
	font-size: $form-note-font-size;
	font-style: italic;
}

.iris-form-group__field,
.iris-form-inputs {
	padding-bottom: 25px;
	width: 100%;
}

@include breakpoint-min(md) {
	// All Form Labels are stacked in mobile.
	// Variants:
	.iris-form-group {
		&--inline {
			flex-direction: row;
			justify-content: space-between;

			& > .iris-form-inputs,
			& > .iris-form-group__field {
				min-width: 0;
			}

			& > .iris-form-label,
			& > .iris-form-group__label {
				min-width: 12rem;
				padding: 8px 20px 8px 0;
				text-align: left;
				width: 100%;
			}
		}
	}

	// Options:
	.iris-form-group {
		&[data-size] {
			justify-content: flex-start;

			& > .iris-form-inputs,
			& > .iris-form-group__field {
				width: 100%;
			}

			& > .iris-form-label,
			& > .iris-form-group__label {
				flex-shrink: 0;
				min-width: auto;
			}
		}

		// Option: Small
		&[data-size='small'] {
			& > .iris-form-label,
			& > .iris-form-group__label {
				width: 86px;
			}
		}

		// Option: Medium
		&[data-size='medium'] {
			& > .iris-form-label,
			& > .iris-form-group__label {
				width: 145px;
			}
		}

		// Option: Large
		&[data-size='large'] {
			& > .iris-form-label,
			& > .iris-form-group__label {
				width: 305px;
			}
		}
	}
}
