// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

@function get-breakpoint($name, $breakpoints: $grid-breakpoints) {
	@return map-get($breakpoints, $name);
}

@mixin breakpoint-min($name, $breakpoints: $grid-breakpoints) {
	@media ( min-width: get-breakpoint( $name, $breakpoints ) ) {
		@content;
	}
}

@mixin breakpoint-max($name, $breakpoints: $grid-breakpoints) {
	@media ( max-width: get-breakpoint( $name, $breakpoints ) ) {
		@content;
	}
}

@mixin breakpoint-between($min, $max, $breakpoints: $grid-breakpoints) {
	@media ( min-width: get-breakpoint( $min, $breakpoints ) ) and ( max-width: get-breakpoint( $max, $breakpoints ) ) {
		@content;
	}
}
