.iris-collapsable--expand-start {
	display: block;
	max-height: 0 !important;
	opacity: 0;
	overflow: hidden;
}

.iris-collapsable--expanding {
	display: block;
	opacity: 1;
	overflow: hidden;
	transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.iris-collapsable--expanded {
	display: block;
}

.iris-collapsable--collapse-start {
	display: block;
}

.iris-collapsable--collapsing {
	display: block;
	max-height: 0 !important;
	opacity: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.iris-collapsable--collapsed {
	display: none;
}

[data-collapsable] {
	cursor: pointer;
}
