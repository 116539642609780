// Variables
// =============================================================================

// Background variables
$drawer-background-default: $colorPlatformWhite;
$drawer-background-footer-default: $colorPlatformGray50;

// Box shadow variables
$drawer-box-shadow-default: transparentize(#000000, 0.85);

// Margin variables
$drawer-margin-default: 20px;

// Padding variables
$drawer-padding-default: 30px;

// Text color variables
$drawer-body-color-primary: $colorPlatformGray800;
$drawer-body-color-secondary: $colorPlatformGray500;

// Width variables
$drawer-width-narrow: 350px;
$drawer-width-wide: 500px;

// z-index variables
$drawer-z-index-default: z-index('drawer');


// Example HTML markup
// =============================================================================
// <div class="iris-drawer">
//  	<header class="iris-drawer__header">
//  		<h2 class="iris-drawer__title">drawer Title</h2>
//  		<button class="iris-button iris-button--ghost" data-boson="icon hidden-text compressed" data-close>
//  			<span class="iris-button__icon fa fa-close" aria-hidden="true"></span>
//  			Close
//  		</button>
//  	</header>
//
//  	<section class="iris-drawer__body">
//  		<p class="iris-drawer__body--primary">Primary text information goes here in 15px font in #333333.</p>
//  		<p class="iris-drawer__body--secondary">Optional secondary text information would be 15px font in #707070.</p>
//  	</section>
//
//  	<footer class="iris-drawer__footer">
//  		<button class="iris-button iris-button--secondary" data-close>Secondary</button>
//  		<button class="iris-button iris-button--primary">Primary</button>
//  	</footer>
// </div>


// Styling
// =============================================================================
.iris-drawer {
	background-color: $drawer-background-default;
	bottom: 0;
	box-shadow: 0 0 4px 0 $drawer-box-shadow-default;
	position: fixed;
	right: 0;
	top: 0;
	transform: translateX(100%);
	transition: transform 400ms ease-in-out;
	z-index: z-index('drawer');
}

.iris-drawer__content {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
}

.iris-drawer__header {
	align-items: center;
	display: flex;
	flex-shrink: 0;
	justify-content: space-between;
	padding: $drawer-padding-default;

	+ .iris-drawer__body {
		padding-top: 0;
	}
}

.iris-drawer__title {
	margin: 0;
}

.iris-drawer__body {
	flex-grow: 2;
	flex-shrink: 2;
	font-size: 1.6rem;
	overflow-y: auto;
	padding: $drawer-padding-default;
}

.iris-drawer__footer {
	align-items: center;
	display: flex;
	flex-shrink: 0;
	flex-wrap: wrap;
	justify-content: flex-end;
	padding: $drawer-padding-default;
}


// States
// =============================================================================
.iris-drawer {
	&--open {
		transform: translateX(0);
	}
}


// Options
// =============================================================================
.iris-drawer {
	&[data-size='narrow'] {
		width: $drawer-width-narrow;
	}

	&[data-size='wide'] {
		width: $drawer-width-wide;
	}
}


// Media Queries
// =============================================================================
@include breakpoint-max(sm) {
	.iris-drawer[data-size='narrow'],
	.iris-drawer[data-size='wide'] {
		width: 100%;
	}
}
