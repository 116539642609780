// Variables
// =============================================================================

// Background colors
$progress-bar-meter-background: $colorPlatformGray100 !default;
$progress-bar-meter-foreground-info: $colorBrandedInfo !default;
$progress-bar-meter-foreground-negative: $colorBrandedError !default;
$progress-bar-meter-foreground-neutral: $colorPlatformGray500 !default;
$progress-bar-meter-foreground-positive: $colorBrandedSuccess !default;

// Font Colors
$progress-bar-title-color: $colorPlatformGray800 !default;
$progress-bar-secondary-info-color-info: $colorPlatformGray500 !default;
$progress-bar-secondary-info-color-positive: $colorBrandedSuccess !default;
$progress-bar-secondary-info-color-negative: $colorBrandedError !default;
$progress-bar-secondary-info-color-neutral: $colorPlatformGray500 !default;


// Example HTML markup
// =============================================================================
// <div class="iris-progress-bar--stacked">
//   <h3 class="iris-progress-bar__title">$350</h3>
//   <progress class="iris-progress-bar__meter"></progress>
//   <span class="iris-progress-bar--secondary-info">$500 required</span>
// </div>


// Base styling
// =============================================================================

.iris-progress-bar {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.iris-progress-bar__title {
	color: $progress-bar-title-color;
	margin-bottom: 10px;
}

.iris-progress-bar__secondary-info {
	margin-top: 5px;
}

.iris-progress-bar__meter {
	background-color: $progress-bar-meter-background;
	border: none;
	border-radius: 999px;
	display: block;
	height: 10px;
	width: 100%;
}

// States
// =============================================================================

// Due to different browsers behaviors the border-radius: 99px was added for each vendor-prefix below

.iris-progress-bar {
	&--info {
		.iris-progress-bar__meter {
			&::-ms-fill {
				background-color: $progress-bar-meter-foreground-info;
				border-radius: 999px;
			}

			&::-moz-progress-bar {
				background-color: $progress-bar-meter-foreground-info;
				border-radius: 999px;
			}

			&::-webkit-progress-value {
				background-color: $progress-bar-meter-foreground-info;
				border-radius: 999px;
			}

			&::-webkit-progress-bar {
				background-color: $progress-bar-meter-background;
				border-radius: 999px;
			}
		}

		.iris-progress-bar__secondary-info {
			color: $progress-bar-secondary-info-color-info;
		}
	}

	&--positive {
		.iris-progress-bar__meter {
			&::-ms-fill {
				background-color: $progress-bar-meter-foreground-positive;
				border-radius: 999px;
			}

			&::-moz-progress-bar {
				background-color: $progress-bar-meter-foreground-positive;
				border-radius: 999px;
			}

			&::-webkit-progress-value {
				background-color: $progress-bar-meter-foreground-positive;
				border-radius: 999px;
			}

			&::-webkit-progress-bar {
				background-color: $progress-bar-meter-background;
				border-radius: 999px;
			}
		}

		.iris-progress-bar__secondary-info {
			color: $progress-bar-secondary-info-color-positive;
		}
	}

	&--negative {
		.iris-progress-bar__meter {
			&::-ms-fill {
				background-color: $progress-bar-meter-foreground-negative;
				border-radius: 999px;
			}

			&::-moz-progress-bar {
				background-color: $progress-bar-meter-foreground-negative;
				border-radius: 999px;
			}

			&::-webkit-progress-value {
				background-color: $progress-bar-meter-foreground-negative;
				border-radius: 999px;
			}

			&::-webkit-progress-bar {
				background-color: $progress-bar-meter-background;
				border-radius: 999px;
			}
		}

		.iris-progress-bar__secondary-info {
			color: $progress-bar-secondary-info-color-negative;
		}
	}

	&--neutral {
		.iris-progress-bar__meter {
			&::-ms-fill {
				background-color: $progress-bar-meter-foreground-neutral;
				border-radius: 999px;
			}

			&::-moz-progress-bar {
				background-color: $progress-bar-meter-foreground-neutral;
				border-radius: 999px;
			}

			&::-webkit-progress-value {
				background-color: $progress-bar-meter-foreground-neutral;
				border-radius: 999px;
			}

			&::-webkit-progress-bar {
				background-color: $progress-bar-meter-background;
				border-radius: 999px;
			}
		}

		.iris-progress-bar__secondary-info {
			color: $progress-bar-secondary-info-color-neutral;
		}
	}
}
