// Variables
// =============================================================================

// Color variables
$popover-background-color: $colorPlatformWhite !default;
$popover-text-color-default: $colorPlatformGray500 !default;
$popover-border-color: $colorPlatformGray100 !default;

// Text variables
$popover-font-size: 1.4rem !default;

// Width variables
$popover-width-small: $container-width-small !default;
$popover-width-medium: $container-width-medium !default;
$popover-width-large: $container-width-large !default;

// Example HTML markup
// =============================================================================
// <div class="iris-popover">
// 	<div class="iris-popover__content">
// 		<span>To the right! To the right!</span>
// 	</div>
// </div>

.iris-popover {
	background: $popover-background-color;
	border: 1px solid $popover-border-color;
	border-radius: 5px;
	box-shadow: 0 20px 50px -5px rgb(0 0 0 / 30%);
	margin: 1rem;
	z-index: 1;

	.iris-popover__header {
		margin: 1rem;
	}

	.iris-popover__content {
		color: $colorPlatformGray500;
		font-size: $popover-font-size;
		margin: 1rem;
	}

	.iris-popover__footer {
		align-items: center;
		display: flex;
		margin: 2rem 1rem 1rem;
	}
}

.iris-popover--expand-start {
	display: block;
	opacity: 0;
}

.iris-popover--expanding {
	display: block;
	opacity: 1;
	transition: opacity 0.2s ease-in-out;
}

.iris-popover--expanded {
	display: block;
}

.iris-popover--collapse-start {
	display: block;
}

.iris-popover--collapsing {
	display: block;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
}

.iris-popover--collapsed {
	display: none;
}

.iris-popover {
	position: relative;

	&::before {
		background: $popover-background-color;
		border: 1px solid $popover-background-color;
		content: '';
		display: block;
		height: 9px;
		position: absolute;
		transform: rotate(45deg);
		width: 9px;
	}

	&[x-indicator='none']::before {
		display: none;
	}

	&[x-placement^='top'] {
		margin-left: 0;
		margin-right: 0;

		&::before {
			border-bottom: 1px solid $popover-border-color;
			border-right: 1px solid $popover-border-color;
			bottom: -5px;
			right: 50%;
		}

		&[x-indicator^='start']::before {
			left: 10px;
			right: auto;
		}

		&[x-indicator^='end']::before {
			right: 10px;
		}
	}

	&[x-placement^='right'] {
		margin-bottom: 0;
		margin-top: 0;

		&::before {
			border-bottom: 1px solid $popover-border-color;
			border-left: 1px solid $popover-border-color;
			bottom: 50%;
			left: -5px;
			margin-bottom: -5px;
		}

		&[x-indicator^='start']::before {
			bottom: auto;
			top: 10px;
		}

		&[x-indicator^='end']::before {
			bottom: 10px;
		}
	}

	&[x-placement^='bottom'] {
		margin-left: 0;
		margin-right: 0;

		&::before {
			border-left: 1px solid $popover-border-color;
			border-top: 1px solid $popover-border-color;
			right: 50%;
			top: -5px;
		}

		&[x-indicator^='start']::before {
			left: 10px;
			right: auto;
		}

		&[x-indicator^='end']::before {
			right: 10px;
		}
	}

	&[x-placement^='left'] {
		margin-bottom: 0;
		margin-top: 0;

		&::before {
			border-right: 1px solid $popover-border-color;
			border-top: 1px solid $popover-border-color;
			bottom: 50%;
			margin-bottom: -5px;
			right: -5px;
		}

		&[x-indicator^='start']::before {
			bottom: auto;
			top: 10px;
		}

		&[x-indicator^='end']::before {
			bottom: 10px;
		}
	}
}

// Decorators
// =============================================================================
.iris-popover {
	&[data-size='small'] {
		width: $popover-width-small;
	}

	&[data-size='medium'] {
		width: $popover-width-medium;
	}

	&[data-size='large'] {
		width: $popover-width-large;
	}
}
