// Component variables
// =============================================================================

// Text variables
$account-font-size-primary-info: 1.6rem !default;
$account-font-size-primary-info-small: 1.4rem !default;
$account-font-size-secondary-info: 1.2rem !default;
$account-multi-line-confined-font-size-primary-info: 1.4rem !default;
$account-multi-line-confined-font-size-secondary-info: 2rem !default;
$account-icon-size-info: 1.6rem !default;
$account-text-primary-info: $colorPlatformGray800 !default;
$account-text-secondary-info: $colorPlatformGray500 !default;
$account-multi-line-confined-text-primary-info: $colorPlatformGray800 !default;
$account-multi-line-confined-text-secondary-info: $colorPlatformGray800 !default;
$account-font-weight-primary-info: 500 !default;
$account-font-weight-secondary-info: 400 !default;
$account-multi-line-confined-font-weight-primary-info: 400 !default;
$account-multi-line-confined-font-weight-secondary-info: 600 !default;


// Example HTML Markup
// =============================================================================
// <div class="iris-account iris-account--multi-line" data-color="account-color-0">
//     <span class="iris-account__color-bar"></span>
//     <div class="iris-account__column iris-account__account-details">
//         <span class="iris-account__info iris-account__primary-info">Steve's Checking Account</span>
//         <div class="iris-account__info iris-account__secondary-info">
//             <span class="iris-account__account-number">**********123</span>
//         </div>
//     </div>
// </div>


// Base Styles
// =============================================================================

// Base account styles:
.iris-account {
	display: flex;
	min-width: 0;
	width: 100%;
}

// Base color bar styles:
.iris-account__color-bar {
	align-self: stretch;
	border-radius: 5px;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 0 5px 0 0;
	width: 5px;
}

// Base account column styles:
.iris-account__column {
	display: flex;

	& + .iris-account-balance {
		padding-left: 10px;
	}
}

// Base info styles:
.iris-account__info {
	align-items: center;
	display: flex;

	& ~ .iris-account__info {
		margin-top: 5px;
	}
}

// Base primary info styles:
.iris-account__primary-info {
	color: $account-text-primary-info;
	font-size: $account-font-size-primary-info;
	font-weight: $account-font-weight-primary-info;
}

// Base secondary info styles:
.iris-account__secondary-info {
	color: $account-text-secondary-info;
	font-size: $account-font-size-secondary-info;
	font-weight: $account-font-weight-secondary-info;
	white-space: nowrap;
}

// Base info icon styles:
.iris-account__info-icon {
	font-size: $account-icon-size-info;
	margin: 0 5px;
}

// Base account name styles:
.iris-account__account-name {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}


// Variants:
// =============================================================================
.iris-account {
	// Standard variant:
	&.iris-account--multi-line {
		justify-content: space-between;

		.iris-account__column {
			flex-direction: column;
			flex-grow: 1;
			flex-shrink: 1;

			&.iris-account__account-details {
				overflow: hidden;
			}
		}
	}

	// Single line variant:
	&.iris-account--single-line {
		align-items: baseline;

		.iris-account__column {
			flex-shrink: 0;

			&:nth-child(2) {
				flex-shrink: 2;
				margin-right: 10px;
				overflow: hidden;

				.iris-account__info {
					overflow: hidden;
				}
			}
		}
	}

	// Multi line confined variant:
	&.iris-account--multi-line-confined {
		justify-content: space-between;

		.iris-account__column {
			flex-direction: column;
			flex-grow: 1;
			flex-shrink: 1;

			&.iris-account__account-details {
				overflow: hidden;
			}

			.iris-account__primary-info {
				color: $account-multi-line-confined-text-primary-info;
				font-size: $account-multi-line-confined-font-size-primary-info;
				font-weight: $account-multi-line-confined-font-weight-primary-info;
				line-height: 20px;
			}

			.iris-account-balance__secondary-info {
				color: $account-multi-line-confined-text-secondary-info;
				font-size: $account-multi-line-confined-font-size-secondary-info;
				font-weight: $account-multi-line-confined-font-weight-secondary-info;
				line-height: 24px;
			}
		}
	}
}


// Decorators
// =============================================================================
.iris-account {
	&[data-size='small'] {
		.iris-account__primary-info {
			font-size: $account-font-size-primary-info-small;
		}

		.iris-account-balance__primary-info,
		.iris-account-balance__icon {
			font-size: $account-font-size-primary-info-small;
		}
	}
}
