// Component Variables
// =============================================================================

// Width variables
$calendar-width: 100% !default;
$calendar-day-name-width: calc(100% / 7) !default;
$calendar-days-spacer: calc(100% / 7) !default;

// Font variables
$calendar-day-name-font-size: 1.2rem !default;
$calendar-day-name-text-color: $colorPlatformGray500 !default;
$calendar-prev-next-arrow-color: $colorPlatformGray500 !default;
$calendar-header-text-color: $colorPlatformGray800 !default;

// Border variables
$calendar-border-bottom-color: $colorPlatformGray100 !default;

// Misc. varibles
$calendar-day-name-margin-bottom: 1.8rem !default;


// Example HTML
// =============================================================================
// <div class="iris-calendar__container">
//     <div class="iris-calendar__header flex flex-items--center">
//         <h2 class="iris-calendar__header-title">June 2018</h2>
//         <button class="iris-button iris-button--ghost iris-calendar__header-prev-month">
//             <span class="iris-button__icon font-icon-angle-left"></span>
//         </button>
//         <button class="iris-button iris-button--ghost iris-calendar__header-next-month">
//             <span class="iris-button__icon font-icon-angle-right"></span>
//         </button>
//     </div>
//     <div class="iris-calendar__days">
//         <div class="iris-calendar__days-header">
//             <span class="iris-calendar__day-name">sun</span>
//             ...
//          ...
//          ...
//             <span class="iris-calendar__day-name">sat</span>
//         </div>
//         <div class="iris-calendar__days-row">
//             <div class="iris-calendar__days-spacer"></div>
//             ...
//          ...
//          ...
//             <button class="iris-day iris-day--past">
//                 <span class="iris-day__number">2</span>
//             </button>
//         </div>
//         <div class="iris-calendar__days-row">
//             <button class="iris-day iris-day--past">
//                 <span class="iris-day__number">3</span>
//             </button>
//             ...
//          ...
//          ...
//             <button class="iris-day iris-day--past">
//                 <span class="iris-day__number">9</span>
//             </button>
//         </div>
//     </div>
// </div>

.iris-calendar {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: $calendar-width;
}

.iris-calendar__header {
    justify-content: center;
    text-align: center;

    &-title {
        color: $calendar-header-text-color;
        font-weight: 500;
        order: 1;
        width: 200px;
    }

    &-prev-month {
        color: $calendar-prev-next-arrow-color;
        order: 0;

        .iris-button__icon {
            font-size: 20px;
        }
    }

    &-next-month {
        color: $calendar-prev-next-arrow-color;
        order: 2;

        .iris-button__icon {
            font-size: 20px;
        }
    }
}

.iris-calendar__sub-header {
    display: flex;
    justify-content: center;
}

.iris-calendar__days {
    margin-top: 30px;
}

.iris-calendar__days-header {
    align-items: center;
    color: $calendar-day-name-text-color;
    display: flex;
    margin-bottom: $calendar-day-name-margin-bottom;
    width: 100%;
}

.iris-calendar__day-name {
    font-size: $calendar-day-name-font-size;
    text-align: center;
    text-transform: uppercase;
    width: $calendar-day-name-width;
}

.iris-calendar__days-row {
    align-items: center;
    display: flex;
}

.iris-calendar__days-spacer {
    background-color: transparent;
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    height: 84px;
    margin: 1px;
    width: $calendar-days-spacer;
}
