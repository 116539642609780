[class*=iris-],
[class*=iris-]::before,
[class*=iris-]::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.col,
.row,
.container,
.container-fluid,
[class*=col-],
[class*=col-]::before,
[class*=col-]::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

[class*=iris-]:not(body) [class*=font-icon-]:empty {
  display: inline-block;
}
[class*=iris-]:not(body) [class*=font-icon-]:empty::before {
  display: block;
}
[class*=iris-]:not(body) [class*=font-icon-]:not(:empty)::before {
  display: inline-block;
}

::-moz-focus-inner {
  border: none;
}

.iris-mouse-use-detected .iris-focus,
.iris-mouse-use-detected *:focus {
  outline: none;
}

.iris-keyboard-use-detected .iris-focus,
.iris-keyboard-use-detected *:focus {
  -webkit-box-shadow: 0 0 4px 2px rgb(2, 103, 193);
          box-shadow: 0 0 4px 2px rgb(2, 103, 193);
  outline: none;
}

.iris-live-region {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/* -- HEADINGS -- */
h1,
.h1 {
  font-size: 3.2rem;
  font-weight: 400;
}

h2,
.h2 {
  font-size: 2.4rem;
  font-weight: 400;
}

h3,
.h3 {
  font-size: 2rem;
  font-weight: 400;
}

h4,
.h4 {
  font-size: 1.8rem;
  font-weight: 400;
}

h5,
h6,
.h5,
.h6 {
  font-size: 1.6rem;
  font-weight: 400;
}

/* -- Inline Types -- */
a {
  background-color: transparent;
  color: rgb(2, 103, 193);
  cursor: pointer;
  text-decoration: none;
}
a:hover, a:focus {
  color: #355988;
}

acronym {
  font-weight: 500;
}

b {
  font-weight: 700;
}

big {
  font-size: 1.25em;
}

code,
kbd,
tt,
var {
  font-family: monospace;
}

cite,
dfn,
em,
i,
var {
  font-style: italic;
}

ins,
u {
  text-decoration: underline;
}

/* -- Block Types -- */
address {
  font-style: italic;
}

p {
  line-height: 1.5;
}

pre {
  display: block;
  font-family: monospace;
  white-space: pre;
}

q::before, q::after {
  content: '"';
}

small {
  font-size: 0.75em;
}

strong {
  font-weight: 600;
}

sub {
  font-size: 0.75em;
  vertical-align: sub;
}

sup {
  font-size: 0.75em;
  vertical-align: super;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.6rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1.25;
}

.iris-content {
  color: rgb(51, 51, 51);
  /* stylelint-disable-next-line no-invalid-position-at-import-rule */
  /* -- HEADINGS -- */
  /* -- Inline Types -- */
  /* -- Block Types -- */
  /* -- Opt-in Styles */
}
.iris-content h1,
.iris-content .h1 {
  font-size: 3.2rem;
  font-weight: 400;
}
.iris-content h2,
.iris-content .h2 {
  font-size: 2.4rem;
  font-weight: 400;
}
.iris-content h3,
.iris-content .h3 {
  font-size: 2rem;
  font-weight: 400;
}
.iris-content h4,
.iris-content .h4 {
  font-size: 1.8rem;
  font-weight: 400;
}
.iris-content h5,
.iris-content h6,
.iris-content .h5,
.iris-content .h6 {
  font-size: 1.6rem;
  font-weight: 400;
}
.iris-content a {
  background-color: transparent;
  color: rgb(2, 103, 193);
  cursor: pointer;
  text-decoration: none;
}
.iris-content a:hover, .iris-content a:focus {
  color: #355988;
}
.iris-content acronym {
  font-weight: 500;
}
.iris-content b {
  font-weight: 700;
}
.iris-content big {
  font-size: 1.25em;
}
.iris-content code,
.iris-content kbd,
.iris-content tt,
.iris-content var {
  font-family: monospace;
}
.iris-content cite,
.iris-content dfn,
.iris-content em,
.iris-content i,
.iris-content var {
  font-style: italic;
}
.iris-content ins,
.iris-content u {
  text-decoration: underline;
}
.iris-content address {
  font-style: italic;
}
.iris-content p {
  line-height: 1.5;
}
.iris-content pre {
  display: block;
  font-family: monospace;
  white-space: pre;
}
.iris-content q::before, .iris-content q::after {
  content: '"';
}
.iris-content small {
  font-size: 0.75em;
}
.iris-content strong {
  font-weight: 600;
}
.iris-content sub {
  font-size: 0.75em;
  vertical-align: sub;
}
.iris-content sup {
  font-size: 0.75em;
  vertical-align: super;
}
.iris-content h1,
.iris-content h2,
.iris-content h3,
.iris-content h4,
.iris-content h5,
.iris-content h6,
.iris-content .h1,
.iris-content .h2,
.iris-content .h3,
.iris-content .h4,
.iris-content .h5,
.iris-content .h6 {
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.iris-content blockquote {
  margin: 1rem 2rem 2rem;
}
.iris-content dd {
  display: block;
  margin-left: 4rem;
}
.iris-content dl {
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.iris-content hr {
  display: block;
  margin-bottom: 2rem;
  margin-top: 1rem;
  width: 100%;
}
.iris-content li {
  list-style: unset;
}
.iris-content ol {
  list-style: decimal;
}
.iris-content p {
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.iris-content pre {
  margin: 1rem 0 2rem;
}
.iris-content table {
  border: 0;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}
.iris-content table th,
.iris-content table td {
  border: 0;
}
.iris-content table th {
  border-bottom: rgb(230, 230, 230) solid 2px;
  color: rgb(117, 117, 117);
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem 2rem;
  text-align: left;
  text-transform: uppercase;
}
.iris-content table td {
  border-bottom: rgb(230, 230, 230) solid 1px;
  padding: 2rem;
}
.iris-content table caption {
  font-size: 1.2rem;
  text-align: center;
}
.iris-content ul,
.iris-content ol {
  margin-bottom: 2rem;
  margin-top: 1rem;
  padding-left: 4rem;
}
.iris-content ul {
  list-style: disc;
}
.iris-content img {
  max-width: 100%;
}

.iris-account-balance {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.iris-account-balance__row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.iris-account-balance__row ~ .iris-account-balance__row {
  margin-top: 5px;
}

.iris-account-balance__primary-info {
  color: rgb(51, 51, 51);
  font-size: 1.6rem;
  font-weight: 500;
}

.iris-account-balance__secondary-info {
  color: rgb(117, 117, 117);
  font-size: 1.2rem;
  font-weight: 400;
}

.iris-account-balance__icon {
  font-size: 1.6rem;
  margin-right: 5px;
}

.available-balance-icon::before {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid rgb(117, 117, 117);
  border-radius: 100%;
  color: rgb(117, 117, 117);
  content: "A";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 8px;
  height: 15px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 15px;
  text-align: center;
  width: 15px;
}

.iris-account-balance--refresh .iris-account-balance__icon {
  color: rgb(2, 103, 193);
}
.iris-account-balance--pending .iris-account-balance__icon {
  color: rgb(117, 117, 117);
}
.iris-account-balance--error .iris-account-balance__icon,
.iris-account-balance--error .iris-account-balance__primary-info {
  color: rgb(206, 22, 22);
}
.iris-account-balance--warning .iris-account-balance__icon,
.iris-account-balance--warning .iris-account-balance__primary-info {
  color: rgb(196, 84, 19);
}

.iris-account[data-size=small] .iris-account-balance__primary-info,
.iris-account[data-size=small] .iris-account-balance__icon {
  font-size: 1.4rem;
}

.iris-account-tile {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.25);
          box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 100%;
}
.iris-account-tile::after {
  background-color: rgb(255, 255, 255);
  content: "";
  display: block;
  height: 100%;
  left: -25%;
  position: absolute;
  right: 0;
  -webkit-transform: translate3d(0, 0, 0) skew(45deg, 0);
          transform: translate3d(0, 0, 0) skew(45deg, 0);
  -webkit-transition: 150ms cubic-bezier(0.5, 0, 0.2, 1);
  transition: 150ms cubic-bezier(0.5, 0, 0.2, 1);
  width: 150%;
}

.iris-account-tile__content {
  background-color: rgb(255, 255, 255);
  border-radius: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  margin: 4px;
  overflow: hidden;
  padding: 7px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
  z-index: 2;
}

.iris-account-tile__content-footer {
  color: rgb(51, 51, 51);
  font-size: 1.2rem;
  line-height: 16px;
  padding: 8px 0 0 10px;
}

.iris-account {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 0;
  width: 100%;
}

.iris-account__color-bar {
  -ms-flex-item-align: stretch;
      align-self: stretch;
  border-radius: 5px;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin: 0 5px 0 0;
  width: 5px;
}

.iris-account__column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.iris-account__column + .iris-account-balance {
  padding-left: 10px;
}

.iris-account__info {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.iris-account__info ~ .iris-account__info {
  margin-top: 5px;
}

.iris-account__primary-info {
  color: rgb(51, 51, 51);
  font-size: 1.6rem;
  font-weight: 500;
}

.iris-account__secondary-info {
  color: rgb(117, 117, 117);
  font-size: 1.2rem;
  font-weight: 400;
  white-space: nowrap;
}

.iris-account__info-icon {
  font-size: 1.6rem;
  margin: 0 5px;
}

.iris-account__account-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.iris-account.iris-account--multi-line {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.iris-account.iris-account--multi-line .iris-account__column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
}
.iris-account.iris-account--multi-line .iris-account__column.iris-account__account-details {
  overflow: hidden;
}
.iris-account.iris-account--single-line {
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
.iris-account.iris-account--single-line .iris-account__column {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.iris-account.iris-account--single-line .iris-account__column:nth-child(2) {
  -ms-flex-negative: 2;
      flex-shrink: 2;
  margin-right: 10px;
  overflow: hidden;
}
.iris-account.iris-account--single-line .iris-account__column:nth-child(2) .iris-account__info {
  overflow: hidden;
}
.iris-account.iris-account--multi-line-confined {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.iris-account.iris-account--multi-line-confined .iris-account__column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
}
.iris-account.iris-account--multi-line-confined .iris-account__column.iris-account__account-details {
  overflow: hidden;
}
.iris-account.iris-account--multi-line-confined .iris-account__column .iris-account__primary-info {
  color: rgb(51, 51, 51);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 20px;
}
.iris-account.iris-account--multi-line-confined .iris-account__column .iris-account-balance__secondary-info {
  color: rgb(51, 51, 51);
  font-size: 2rem;
  font-weight: 600;
  line-height: 24px;
}

.iris-account[data-size=small] .iris-account__primary-info {
  font-size: 1.4rem;
}
.iris-account[data-size=small] .iris-account-balance__primary-info,
.iris-account[data-size=small] .iris-account-balance__icon {
  font-size: 1.4rem;
}

.iris-avatar {
  border-radius: 100%;
  position: relative;
}

.iris-avatar__image {
  min-height: 100%;
  min-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.iris-avatar__text {
  font-weight: 700;
  line-height: 1;
}

.iris-avatar__inner {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
}

.iris-avatar--editible .iris-avatar__inner {
  position: relative;
}
.iris-avatar--editible .iris-avatar__inner::before {
  background-color: rgba(51, 51, 51, 0.7);
  border-radius: 100%;
  content: "";
  inset: 0;
  position: absolute;
  z-index: 10;
}
.iris-avatar--editible .iris-avatar__edit-button {
  border-radius: 100%;
  height: 100%;
  inset: 0;
  padding: 0.3rem;
  position: absolute;
  width: 100%;
  z-index: 11;
}
.iris-avatar--editible .iris-avatar__edit-button .iris-button__icon::before,
.iris-avatar--editible .iris-avatar__edit-button .iris-button__icon::after {
  color: rgb(255, 255, 255);
}
.iris-avatar--editible .iris-avatar__edit-button:hover .iris-button__icon::before,
.iris-avatar--editible .iris-avatar__edit-button:hover .iris-button__icon::after {
  color: #cccccc;
}

.iris-avatar[data-size=xsmall] .iris-avatar__inner {
  height: 2rem;
  width: 2rem;
}
.iris-avatar[data-size=xsmall] .iris-avatar__icon::before,
.iris-avatar[data-size=xsmall] .iris-avatar__icon::after {
  font-size: 1rem;
}
.iris-avatar[data-size=xsmall] .iris-avatar__text {
  font-size: 1rem;
}
.iris-avatar[data-size=xsmall].iris-avatar--editible .iris-button__icon::before,
.iris-avatar[data-size=xsmall].iris-avatar--editible .iris-button__icon::after {
  font-size: 1rem;
}
.iris-avatar[data-size=small] .iris-avatar__inner {
  height: 4rem;
  width: 4rem;
}
.iris-avatar[data-size=small] .iris-avatar__icon::before,
.iris-avatar[data-size=small] .iris-avatar__icon::after {
  font-size: 1.6rem;
}
.iris-avatar[data-size=small] .iris-avatar__text {
  font-size: 1.6rem;
}
.iris-avatar[data-size=small].iris-avatar--editible .iris-button__icon::before,
.iris-avatar[data-size=small].iris-avatar--editible .iris-button__icon::after {
  font-size: 1.6rem;
}
.iris-avatar[data-size=medium] .iris-avatar__inner {
  height: 5rem;
  width: 5rem;
}
.iris-avatar[data-size=medium] .iris-avatar__icon::before,
.iris-avatar[data-size=medium] .iris-avatar__icon::after {
  font-size: 2.5rem;
  line-height: 2.5rem;
}
.iris-avatar[data-size=medium] .iris-avatar__text {
  font-size: 2rem;
}
.iris-avatar[data-size=medium].iris-avatar--editible .iris-button__icon::before,
.iris-avatar[data-size=medium].iris-avatar--editible .iris-button__icon::after {
  font-size: 2.5rem;
}
.iris-avatar[data-size=large] .iris-avatar__inner {
  height: 6rem;
  width: 6rem;
}
.iris-avatar[data-size=large] .iris-avatar__icon::before,
.iris-avatar[data-size=large] .iris-avatar__icon::after {
  font-size: 3rem;
  line-height: 3rem;
}
.iris-avatar[data-size=large] .iris-avatar__text {
  font-size: 2.4rem;
}
.iris-avatar[data-size=large].iris-avatar--editible .iris-button__icon::before,
.iris-avatar[data-size=large].iris-avatar--editible .iris-button__icon::after {
  font-size: 3rem;
}
.iris-avatar[data-size=xlarge] .iris-avatar__inner {
  height: 8rem;
  width: 8rem;
}
.iris-avatar[data-size=xlarge] .iris-avatar__icon::before,
.iris-avatar[data-size=xlarge] .iris-avatar__icon::after {
  font-size: 4rem;
  line-height: 4rem;
}
.iris-avatar[data-size=xlarge] .iris-avatar__text {
  font-size: 3.2rem;
}
.iris-avatar[data-size=xlarge].iris-avatar--editible .iris-button__icon::before,
.iris-avatar[data-size=xlarge].iris-avatar--editible .iris-button__icon::after {
  font-size: 4rem;
}

.iris-backdrop {
  overflow: hidden;
}
.iris-backdrop::before {
  content: "";
  cursor: default;
  display: block;
  inset: 0;
  opacity: 0;
  position: fixed;
  -webkit-transition: 300ms all linear;
  transition: 300ms all linear;
  z-index: 900;
}
.iris-backdrop--dark::before {
  background-color: rgba(0, 0, 0, 0.8);
}
.iris-backdrop--light::before {
  background-color: rgba(0, 0, 0, 0.4);
}

.iris-backdrop--open::before {
  opacity: 1;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
body.no-scroll {
  overflow: hidden;
}

.iris-badge {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1.2rem;
  font-weight: 600;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1.2rem;
  text-transform: uppercase;
  -webkit-transition: 200ms all linear;
  transition: 200ms all linear;
}

.iris-badge--squared {
  background-color: rgb(117, 117, 117);
  border-radius: 2px;
  color: rgb(255, 255, 255);
  padding: 3px 8px;
}
.iris-badge--rounded {
  border-radius: 1.5rem;
  padding: 3px 8px;
}
.iris-badge--circular {
  border-color: rgb(255, 255, 255);
  border-radius: 2rem;
  border-style: solid;
  border-width: 2px;
  line-height: 1;
  padding: 3px;
}
.iris-badge--circular:empty {
  padding: 4px;
}

.iris-badge.iris-badge--positive {
  background-color: rgb(27, 124, 53);
  color: rgb(255, 255, 255);
}
.iris-badge.iris-badge--negative {
  background-color: rgb(206, 22, 22);
  color: rgb(255, 255, 255);
}
.iris-badge.iris-badge--neutral {
  background-color: rgb(117, 117, 117);
  color: rgb(255, 255, 255);
}
.iris-badge.iris-badge--info {
  background-color: rgb(2, 103, 193);
  color: rgb(255, 255, 255);
}
.iris-badge.iris-badge--polite {
  background-color: rgb(242, 242, 242);
  color: rgb(117, 117, 117);
}
.iris-badge.iris-badge--caution {
  background-color: rgb(196, 84, 19);
  color: rgb(255, 255, 255);
}

.iris-badge[data-position=top-left] {
  position: absolute;
}
.iris-badge[data-position=top-left]:not(.iris-badge--circular), .iris-badge[data-position=top-left].iris-badge--circular:empty {
  left: 0;
  top: 0;
}
.iris-badge[data-position=top-left].iris-badge--squared {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.iris-badge[data-position=top-left].iris-badge--circular:not(:empty) {
  left: -5px;
  top: -5px;
}
.iris-badge[data-position=top-right] {
  position: absolute;
}
.iris-badge[data-position=top-right]:not(.iris-badge--circular), .iris-badge[data-position=top-right].iris-badge--circular:empty {
  right: 0;
  top: 0;
}
.iris-badge[data-position=top-right].iris-badge--squared {
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.iris-badge[data-position=top-right].iris-badge--circular:not(:empty) {
  right: -5px;
  top: -5px;
}
.iris-badge[data-position=bottom-left] {
  position: absolute;
}
.iris-badge[data-position=bottom-left]:not(.iris-badge--circular), .iris-badge[data-position=bottom-left].iris-badge--circular:empty {
  bottom: 0;
  left: 0;
}
.iris-badge[data-position=bottom-left].iris-badge--squared {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
}
.iris-badge[data-position=bottom-left].iris-badge--circular:not(:empty) {
  bottom: -5px;
  left: -5px;
}
.iris-badge[data-position=bottom-right] {
  position: absolute;
}
.iris-badge[data-position=bottom-right]:not(.iris-badge--circular), .iris-badge[data-position=bottom-right].iris-badge--circular:empty {
  bottom: 0;
  right: 0;
}
.iris-badge[data-position=bottom-right].iris-badge--squared {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.iris-badge[data-position=bottom-right].iris-badge--circular:not(:empty) {
  bottom: -5px;
  right: -5px;
}

.iris-button {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid;
  border-radius: var(--shapeBrandedSmall, 3px);
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: 400;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1.4rem;
  padding: 12px 15px;
  -webkit-transition: 200ms all linear;
  transition: 200ms all linear;
}
.iris-button + .iris-button {
  margin-left: 1rem;
}

.iris-button__icon:not(:only-child):first-child {
  margin-right: 5px;
}
.iris-button__icon:not(:only-child):last-child {
  margin-left: 5px;
}

.iris-button--action {
  background-color: rgb(255, 255, 255);
  border-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
  font-weight: 400;
}
.iris-button--ghost {
  background-color: transparent;
  border-color: transparent;
  color: rgb(2, 103, 193);
}
.iris-button--circular {
  background-color: rgb(255, 255, 255);
  border-color: rgb(230, 230, 230);
  border-radius: 2rem;
  color: rgb(117, 117, 117);
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 1.8rem;
  height: 4rem;
  padding: 0;
  width: 4rem;
}
.iris-button--circular .iris-button__icon {
  display: block;
  margin: auto;
  text-align: center;
  width: 100%;
}

.iris-button:hover.iris-button--action, .iris-button:focus.iris-button--action {
  background-color: rgb(242, 242, 242);
  border-color: rgb(51, 51, 51);
  color: rgb(51, 51, 51);
}
.iris-button:hover.iris-button--ghost, .iris-button:focus.iris-button--ghost {
  color: #014c8f;
}
.iris-button:hover.iris-button--circular, .iris-button:focus.iris-button--circular {
  background-color: rgb(242, 242, 242);
  border-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
}
.iris-keyboard-use-detected .iris-button:focus {
  -webkit-box-shadow: 0 0 4px 2px rgb(2, 103, 193);
          box-shadow: 0 0 4px 2px rgb(2, 103, 193);
  outline: none;
}
.iris-button--disabled, .iris-button--disabled:hover, .iris-button--disabled:focus, .iris-button:disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.iris-button--disabled.iris-button--action, .iris-button--disabled:hover.iris-button--action, .iris-button--disabled:focus.iris-button--action, .iris-button:disabled.iris-button--action {
  background-color: white;
  border-color: #bababa;
  color: #bababa;
}
.iris-button--disabled.iris-button--ghost, .iris-button--disabled:hover.iris-button--ghost, .iris-button--disabled:focus.iris-button--ghost, .iris-button:disabled.iris-button--ghost {
  color: #81b3e0;
}
.iris-button--disabled:focus, .iris-button--disabled:hover:focus, .iris-button--disabled:focus:focus, .iris-button:disabled:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.iris-button[data-size*=full-width] {
  width: 100%;
}
.iris-button[data-size*=full-width] + .iris-button {
  margin-left: 0;
}
.iris-button[data-modifier*=compressed] {
  font-size: 1.4rem;
  padding: 6px 12px;
}
.iris-button[data-modifier*=pill] {
  border-radius: 40px;
}
.iris-button[data-modifier*=destructive].iris-button--circular, .iris-button[data-modifier*=destructive].iris-button--ghost {
  color: rgb(206, 22, 22);
}
.iris-button[data-modifier*=destructive].iris-button--circular.iris-button--hover, .iris-button[data-modifier*=destructive].iris-button--circular:hover, .iris-button[data-modifier*=destructive].iris-button--circular:focus, .iris-button[data-modifier*=destructive].iris-button--ghost.iris-button--hover, .iris-button[data-modifier*=destructive].iris-button--ghost:hover, .iris-button[data-modifier*=destructive].iris-button--ghost:focus {
  color: #a01111;
}
.iris-button[data-modifier*=destructive].iris-button--circular.iris-button--disabled, .iris-button[data-modifier*=destructive].iris-button--circular.iris-button--disabled:hover, .iris-button[data-modifier*=destructive].iris-button--circular.iris-button--disabled:focus, .iris-button[data-modifier*=destructive].iris-button--circular:disabled, .iris-button[data-modifier*=destructive].iris-button--ghost.iris-button--disabled, .iris-button[data-modifier*=destructive].iris-button--ghost.iris-button--disabled:hover, .iris-button[data-modifier*=destructive].iris-button--ghost.iris-button--disabled:focus, .iris-button[data-modifier*=destructive].iris-button--ghost:disabled {
  color: #e78b8b;
}
.iris-button[data-modifier*=destructive].iris-button--circular {
  border-color: rgb(206, 22, 22);
}
.iris-button[data-modifier*=destructive].iris-button--circular:hover, .iris-button[data-modifier*=destructive].iris-button--circular:focus {
  border-color: rgb(206, 22, 22);
}
.iris-button[data-modifier*=destructive].iris-button--circular:disabled, .iris-button[data-modifier*=destructive].iris-button--circular:disabled:hover, .iris-button[data-modifier*=destructive].iris-button--circular:disabled:focus {
  border-color: #e78b8b;
}
.iris-button[data-modifier*=neutral].iris-button--circular, .iris-button[data-modifier*=neutral].iris-button--ghost {
  color: rgb(117, 117, 117);
}
.iris-button[data-modifier*=neutral].iris-button--circular.iris-button--hover, .iris-button[data-modifier*=neutral].iris-button--circular:hover, .iris-button[data-modifier*=neutral].iris-button--circular:focus, .iris-button[data-modifier*=neutral].iris-button--ghost.iris-button--hover, .iris-button[data-modifier*=neutral].iris-button--ghost:hover, .iris-button[data-modifier*=neutral].iris-button--ghost:focus {
  color: #5c5c5c;
}
.iris-button[data-modifier*=neutral].iris-button--circular.iris-button--disabled, .iris-button[data-modifier*=neutral].iris-button--circular.iris-button--disabled:hover, .iris-button[data-modifier*=neutral].iris-button--circular.iris-button--disabled:focus, .iris-button[data-modifier*=neutral].iris-button--circular:disabled, .iris-button[data-modifier*=neutral].iris-button--ghost.iris-button--disabled, .iris-button[data-modifier*=neutral].iris-button--ghost.iris-button--disabled:hover, .iris-button[data-modifier*=neutral].iris-button--ghost.iris-button--disabled:focus, .iris-button[data-modifier*=neutral].iris-button--ghost:disabled {
  color: #e78b8b;
}

.iris-calendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: 100%;
}

.iris-calendar__header {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
}
.iris-calendar__header-title {
  color: rgb(51, 51, 51);
  font-weight: 500;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  width: 200px;
}
.iris-calendar__header-prev-month {
  color: rgb(117, 117, 117);
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
}
.iris-calendar__header-prev-month .iris-button__icon {
  font-size: 20px;
}
.iris-calendar__header-next-month {
  color: rgb(117, 117, 117);
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.iris-calendar__header-next-month .iris-button__icon {
  font-size: 20px;
}

.iris-calendar__sub-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.iris-calendar__days {
  margin-top: 30px;
}

.iris-calendar__days-header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: rgb(117, 117, 117);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1.8rem;
  width: 100%;
}

.iris-calendar__day-name {
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  width: 14.2857142857%;
}

.iris-calendar__days-row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.iris-calendar__days-spacer {
  background-color: transparent;
  border-radius: 3px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 84px;
  margin: 1px;
  width: 14.2857142857%;
}

.iris-card {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  -webkit-transition: 200ms all linear;
  transition: 200ms all linear;
}

.iris-card__header {
  margin: 20px;
}

.iris-card__title {
  margin: 0;
}

.iris-card__content {
  color: rgb(117, 117, 117);
  margin: 20px;
}

.iris-card__image {
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  width: 100%;
}

.iris-card__overlay {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  inset: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
}

.iris-card__footer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px;
}

.iris-card--flat {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.iris-card--shadow {
  -webkit-box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.3);
}
.iris-card--image {
  overflow: hidden;
}
.iris-card--image .iris-card__content {
  margin: 0;
  position: relative;
}

.iris-card--draggable {
  border-color: rgb(117, 117, 117);
  border-style: dashed;
  border-width: 2px;
  cursor: move;
}

.iris-card[data-size=small] {
  width: 260px;
}
.iris-card[data-size=medium] {
  width: 300px;
}
.iris-card[data-size=large] {
  width: 500px;
}
.iris-card[data-size=full-width] {
  width: 100%;
}
.iris-card[data-hoverable].iris-card--shadow:hover {
  -webkit-box-shadow: 0 15px 20px -5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 15px 20px -5px rgba(0, 0, 0, 0.3);
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}
.iris-card[data-hoverable].iris-card--shadow:focus-within {
  -webkit-box-shadow: 0 15px 20px -5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 15px 20px -5px rgba(0, 0, 0, 0.3);
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.iris-card .iris-badge--squared[data-position=top-left] {
  border-bottom-right-radius: 4px;
  border-top-left-radius: 4px;
}
.iris-card .iris-badge--squared[data-position=top-right] {
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
}
.iris-card .iris-badge--squared[data-position=bottom-left] {
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
}
.iris-card .iris-badge--squared[data-position=bottom-right] {
  border-bottom-right-radius: 4px;
  border-top-left-radius: 4px;
}

.iris-checkbox__input {
  height: 22px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  width: 22px;
  z-index: -1;
}
.iris-checkbox__input:checked ~ .iris-checkbox__label::before {
  background: url("data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20viewBox%3D%220%200%20512%20512%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m192%20432l-168.18-177.8c-10.904-11.146-10.707-29.02%200.438-39.924%2011.146-10.904%2029.02-10.707%2039.924%200.438%200.164%200.168%200.326%200.338%200.486%200.509l127.34%20136.78%20255.55-264.06c10.884-11.165%2028.759-11.392%2039.924-0.508s11.392%2028.759%200.508%2039.924l-295.98%20304.64z%22%20fill%3D%22%230267C1%22%2F%3E%3C%2Fsvg%3E");
  background-color: rgb(255, 255, 255);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 73%;
  border-color: rgb(117, 117, 117);
}
.iris-checkbox__input:focus ~ .iris-checkbox__label::before {
  border-color: rgb(2, 103, 193);
  -webkit-box-shadow: 0 0 4px 2px rgb(2, 103, 193);
          box-shadow: 0 0 4px 2px rgb(2, 103, 193);
}

.iris-checkbox__label {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.4rem;
  font-weight: 600;
}
.iris-checkbox__label::before {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(117, 117, 117);
  border-radius: 3px;
  color: rgb(2, 103, 193);
  content: "";
  display: block;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: 22px;
  line-height: 22px;
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
  text-align: center;
  -webkit-transition: border-color 200ms linear, background-color 200ms linear;
  transition: border-color 200ms linear, background-color 200ms linear;
  width: 22px;
}
.iris-checkbox__label:hover::before {
  border-color: rgb(51, 51, 51);
}

.iris-checkbox__content {
  margin-left: 10px;
}

.iris-checkbox__extended-content {
  margin-left: 32px;
  margin-top: 5px;
}

.iris-checkbox__description {
  color: rgb(117, 117, 117);
  display: inline-block;
  font-size: 1.4rem;
}

.iris-checkbox--disabled .iris-checkbox__input ~ .iris-checkbox__label,
.iris-checkbox .iris-checkbox__input:disabled ~ .iris-checkbox__label {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
.iris-checkbox--disabled .iris-checkbox__input ~ .iris-checkbox__label:hover::before,
.iris-checkbox .iris-checkbox__input:disabled ~ .iris-checkbox__label:hover::before {
  border-color: rgb(117, 117, 117);
}
.iris-checkbox--disabled .iris-checkbox__input:focus ~ .iris-checkbox__label::before,
.iris-checkbox .iris-checkbox__input:disabled:focus ~ .iris-checkbox__label::before {
  border-color: rgb(117, 117, 117);
  -webkit-box-shadow: transparent;
          box-shadow: transparent;
}

.iris-checkbox[data-modifier=inverted] .iris-checkbox__label {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.iris-checkbox[data-modifier=inverted] .iris-checkbox__label::before {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.iris-checkbox[data-modifier=inverted] .iris-checkbox__content {
  margin-left: 0;
  margin-right: 10px;
}
.iris-checkbox[data-modifier=inverted] .iris-checkbox__extended-content {
  margin-left: 0;
  margin-right: 32px;
}

.iris-chevron,
.iris-chevron--down {
  display: block;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  position: relative;
  width: 1.6rem;
}
.iris-chevron::after, .iris-chevron::before,
.iris-chevron--down::after,
.iris-chevron--down::before {
  border-bottom: solid 2px rgb(117, 117, 117);
  content: "";
  height: 0;
  position: absolute;
  top: 0;
  -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s;
  width: 1rem;
}
.iris-chevron::before,
.iris-chevron--down::before {
  left: 0;
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
}
.iris-chevron::after,
.iris-chevron--down::after {
  right: 0;
  -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg);
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
}

.iris-chevron--up::before {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
}
.iris-chevron--up::after {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
}

.iris-chevron--right,
.iris-chevron--left {
  border-bottom: 2px solid rgb(117, 117, 117);
  border-left: 2px solid rgb(117, 117, 117);
  display: inline-block;
  height: 1.4rem;
  width: 1.4rem;
}
.iris-chevron--right::before, .iris-chevron--right::after,
.iris-chevron--left::before,
.iris-chevron--left::after {
  content: none;
}
.iris-chevron--right[data-size=xs],
.iris-chevron--left[data-size=xs] {
  height: 1rem;
  width: 1rem;
}
.iris-chevron--right[data-size=sm],
.iris-chevron--left[data-size=sm] {
  height: 1.4rem;
  width: 1.4rem;
}
.iris-chevron--right[data-size=md],
.iris-chevron--left[data-size=md] {
  height: 1.6rem;
  width: 1.6rem;
}
.iris-chevron--right[data-size=lg],
.iris-chevron--left[data-size=lg] {
  height: 2rem;
  width: 2rem;
}
.iris-chevron--right[data-size=xl],
.iris-chevron--left[data-size=xl] {
  height: 2.4rem;
  width: 2.4rem;
}

.iris-chevron--right {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}

.iris-chevron--left {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.iris-collapsable--expand-start {
  display: block;
  max-height: 0 !important;
  opacity: 0;
  overflow: hidden;
}

.iris-collapsable--expanding {
  display: block;
  opacity: 1;
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.iris-collapsable--expanded {
  display: block;
}

.iris-collapsable--collapse-start {
  display: block;
}

.iris-collapsable--collapsing {
  display: block;
  max-height: 0 !important;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.iris-collapsable--collapsed {
  display: none;
}

[data-collapsable] {
  cursor: pointer;
}

.iris-compass {
  width: 100%;
}
.iris-compass__header {
  margin: 20px 0;
  width: 100%;
}
.iris-compass__steps {
  margin: 20px 0;
  position: relative;
  width: 100%;
}
.iris-compass__footer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  width: 100%;
}

.iris-compass-step {
  display: none;
  width: 100%;
}
.iris-compass-step__body {
  margin: 20px 0 40px;
}
.iris-compass-step__footer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin: 20px 0;
}
.iris-compass-step__header {
  margin: 20px 0;
}

[data-compass-active=true] {
  display: block;
}

[data-compass-active=false] {
  display: none;
}

[data-compass-active].iris-compass--active-false {
  display: none;
  opacity: 0;
}
[data-compass-active].iris-compass--transition-active-true {
  display: block;
  opacity: 0;
  -webkit-transition: opacity 250ms ease-in;
  transition: opacity 250ms ease-in;
}
[data-compass-active].iris-compass--transitioning-active-true {
  opacity: 1;
}
[data-compass-active].iris-compass--active-true {
  display: block;
  opacity: 1;
}

.iris-drawer__back-button {
  margin-left: 0;
  overflow: hidden;
  width: 36px;
}
.iris-drawer__back-button.iris-compass-active-false {
  padding: 6px 0;
  width: 0;
}
.iris-drawer__back-button.iris-compass--transition-active-true {
  display: block;
  padding: 6px 0;
  -webkit-transition: width 250ms ease-in, padding 250ms ease-in;
  transition: width 250ms ease-in, padding 250ms ease-in;
  width: 0;
}
.iris-drawer__back-button.iris-compass--transitioning-active-true {
  opacity: 1;
  padding: 6px 12px;
  width: 36px;
}
.iris-drawer__back-button.iris-compass--active-true {
  opacity: 1;
  padding: 6px 12px;
  width: 36px;
}

.iris-content-header {
  border-bottom: 2px solid rgb(230, 230, 230);
  padding: 10px 0;
  width: 100%;
}

.iris-content-header__item {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: rgb(117, 117, 117);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: 200ms all linear;
  transition: 200ms all linear;
}

.iris-content-header__title {
  margin: 0;
}

/* stylelint-disable comment-empty-line-before, selector-no-qualifying-type */
button.iris-content-header__item {
  background: none;
  border: 0;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
button.iris-content-header__item::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* stylelint-enable */
.iris-content-header__item[data-sortable] {
  cursor: pointer;
}
.iris-content-header__item[data-sortable]:not(.iris-content-header__item--ascending, .iris-content-header__item--descending):hover {
  color: #292929;
}
.iris-content-header__item[data-sortable]:not(.iris-content-header__item--ascending, .iris-content-header__item--descending):hover .iris-content-header__sorter::before {
  border-bottom-color: #292929;
}
.iris-content-header__item[data-sortable]:not(.iris-content-header__item--ascending, .iris-content-header__item--descending):hover .iris-content-header__sorter::after {
  border-top-color: #292929;
}
.iris-content-header__item[data-sortable] .iris-content-header__sorter {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 5px;
}
.iris-content-header__item[data-sortable] .iris-content-header__sorter::before, .iris-content-header__item[data-sortable] .iris-content-header__sorter::after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: "";
  display: block;
  -webkit-transition: 300ms all linear;
  transition: 300ms all linear;
}
.iris-content-header__item[data-sortable] .iris-content-header__sorter::before {
  border-bottom: 7px solid rgba(117, 117, 117, 0.5);
  margin-bottom: 2px;
}
.iris-content-header__item[data-sortable] .iris-content-header__sorter::after {
  border-top: 7px solid rgba(117, 117, 117, 0.5);
}
.iris-content-header__item[data-sortable].iris-content-header__item--ascending {
  color: rgb(51, 51, 51);
}
.iris-content-header__item[data-sortable].iris-content-header__item--ascending .iris-content-header__sorter::before {
  border-bottom-color: rgb(51, 51, 51);
}
.iris-content-header__item[data-sortable].iris-content-header__item--descending {
  color: rgb(51, 51, 51);
}
.iris-content-header__item[data-sortable].iris-content-header__item--descending .iris-content-header__sorter::after {
  border-top-color: rgb(51, 51, 51);
}

.iris-date {
  text-align: center;
}
.iris-date .iris-date__month {
  text-transform: uppercase;
}

.iris-date--linear {
  color: rgb(117, 117, 117);
  font-size: 1.2rem;
}
.iris-date--stacked-full .iris-date__month {
  display: block;
  font-size: 1.1rem;
}
.iris-date--stacked-full .iris-date__day {
  display: block;
  font-size: 1.8rem;
  font-weight: 700;
}
.iris-date--stacked-full .iris-date__year {
  display: block;
  font-size: 0.9rem;
}
.iris-date--stacked-month-year .iris-date__month {
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
}
.iris-date--stacked-month-year .iris-date__day {
  display: none;
}
.iris-date--stacked-month-year .iris-date__year {
  display: block;
  font-size: 1.4rem;
}
.iris-date--stacked-full .iris-date__month, .iris-date--stacked-month-year .iris-date__month {
  color: rgb(117, 117, 117);
}
.iris-date--stacked-full .iris-date__day, .iris-date--stacked-month-year .iris-date__day {
  color: rgb(117, 117, 117);
}
.iris-date--stacked-full .iris-date__year, .iris-date--stacked-month-year .iris-date__year {
  color: rgb(117, 117, 117);
}

.iris-date__full-date {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.iris-day {
  -webkit-box-align: normal;
      -ms-flex-align: normal;
          align-items: normal;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 230, 230);
  border-radius: 3px;
  color: rgb(51, 51, 51);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 84px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 1px;
  overflow: hidden;
  padding: 5px;
  position: relative;
  text-align: left;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 14.2857142857%;
  z-index: 10;
}
.iris-day:first-child {
  margin-left: 0;
}
.iris-day:last-child {
  margin-right: 0;
}
.iris-day .iris-day__button {
  background-color: transparent;
  border-style: none;
  color: inherit;
  cursor: pointer;
  font-weight: inherit;
  height: 100%;
  padding: 0;
  width: 100%;
}
.iris-day .iris-day__button-wrapper {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  color: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  font-weight: inherit;
  height: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.iris-day .iris-avatar[data-size=xsmall] {
  left: -2px;
}
.iris-day .iris-avatar[data-size=xsmall] .iris-avatar__inner {
  height: 2.6rem;
  width: 2.6rem;
}

.iris-day__number {
  font-size: 1.8rem;
  padding-bottom: 0.5rem;
}

.iris-day__label {
  color: rgb(117, 117, 117);
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  font-size: 1.2rem;
  font-weight: initial;
  text-align: left;
}

.iris-day__avatar .iris-avatar__inner {
  border: 2px solid #FFFFFF;
}
.iris-day__avatar::before, .iris-day__avatar::after {
  border: 2px solid #FFFFFF;
  border-radius: 100%;
  display: block;
  height: 2.6rem;
  position: absolute;
  top: 0;
  width: 2.6rem;
}
.iris-day__avatar[data-stack]::before {
  content: "";
  -webkit-transform: translateX(20%);
          transform: translateX(20%);
  z-index: -1;
}
.iris-day__avatar[data-stack="3"]::after {
  content: "";
  -webkit-transform: translateX(40%);
          transform: translateX(40%);
  z-index: -2;
}

.ripple {
  -webkit-animation: ripple-effect 0.3s linear;
          animation: ripple-effect 0.3s linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  border-radius: 50%;
  display: block;
  height: 10px;
  position: absolute;
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  width: 10px;
  z-index: -1;
}

.iris-day:hover:not(.disabled-day) {
  background-color: rgb(242, 242, 242);
}
.iris-day.iris-day--past {
  color: rgb(117, 117, 117);
}
.iris-day.disabled-day {
  cursor: not-allowed;
}
.iris-day .iris-day__button:disabled {
  color: rgb(117, 117, 117);
  cursor: not-allowed;
}
.iris-day.iris-day--today {
  color: rgb(51, 51, 51);
  font-weight: 700;
}
.iris-day.selected {
  -webkit-box-shadow: 0 10px 20px rgba(51, 51, 51, 0.5);
          box-shadow: 0 10px 20px rgba(51, 51, 51, 0.5);
  position: relative;
  z-index: 20;
}
.iris-day.selected .iris-avatar__inner {
  border: none;
}

@-webkit-keyframes ripple-effect {
  to {
    opacity: 1;
    -webkit-transform: scale(3, 3);
            transform: scale(3, 3);
  }
}

@keyframes ripple-effect {
  to {
    opacity: 1;
    -webkit-transform: scale(3, 3);
            transform: scale(3, 3);
  }
}
.iris-drawer {
  background-color: rgb(255, 255, 255);
  bottom: 0;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: -webkit-transform 400ms ease-in-out;
  transition: -webkit-transform 400ms ease-in-out;
  transition: transform 400ms ease-in-out;
  transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
  z-index: 1000;
}

.iris-drawer__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.iris-drawer__header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 30px;
}
.iris-drawer__header + .iris-drawer__body {
  padding-top: 0;
}

.iris-drawer__title {
  margin: 0;
}

.iris-drawer__body {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  -ms-flex-negative: 2;
      flex-shrink: 2;
  font-size: 1.6rem;
  overflow-y: auto;
  padding: 30px;
}

.iris-drawer__footer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 30px;
}

.iris-drawer--open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.iris-drawer[data-size=narrow] {
  width: 350px;
}
.iris-drawer[data-size=wide] {
  width: 500px;
}

@media (max-width: 578px) {
  .iris-drawer[data-size=narrow],
  .iris-drawer[data-size=wide] {
    width: 100%;
  }
}
.iris-dropdown {
  display: block;
  font-size: 1.4rem;
  position: relative;
  width: 100%;
}
.iris-dropdown .iris-chevron {
  cursor: pointer;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
}
.iris-dropdown .iris-dropdown__capture-input {
  border-radius: 3px;
  display: block;
  inset: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  -webkit-transition: 200ms all linear;
  transition: 200ms all linear;
  width: 100%;
  z-index: -1;
}
.iris-dropdown .iris-dropdown__capture-input:focus + .iris-dropdown__selected-value, .iris-dropdown .iris-dropdown__capture-input:active + .iris-dropdown__selected-value {
  -webkit-box-shadow: 0 0 4px 2px rgb(2, 103, 193);
          box-shadow: 0 0 4px 2px rgb(2, 103, 193);
  outline: none;
}
.iris-dropdown .iris-dropdown__selected-value {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(117, 117, 117);
  border-radius: 3px;
  color: rgb(51, 51, 51);
  cursor: pointer;
  display: block;
  font-size: 1.4rem;
  line-height: 1.25;
  overflow: hidden;
  padding: 10px 30px 10px 10px;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-transition: 200ms all linear;
  transition: 200ms all linear;
  white-space: nowrap;
  width: 100%;
}
.iris-dropdown .iris-dropdown__placeholder {
  color: rgb(117, 117, 117);
}
.iris-dropdown .iris-option {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid rgb(230, 230, 230);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  line-height: 1.25;
  min-height: 35px;
  padding: 10px;
  position: relative;
}
.iris-dropdown .iris-option[disabled] {
  opacity: 0.5;
}
.iris-dropdown .iris-option[data-active=true], .iris-dropdown .iris-option:hover {
  color: #000000;
}
.iris-dropdown .iris-option[aria-selected=true] {
  background: rgb(242, 242, 242);
}
.iris-dropdown .iris-option[aria-selected=true], .iris-dropdown .iris-option[data-active=true], .iris-dropdown .iris-option:hover {
  -webkit-box-shadow: inset 1px 1px rgb(117, 117, 117), inset -1px -1px rgb(117, 117, 117);
          box-shadow: inset 1px 1px rgb(117, 117, 117), inset -1px -1px rgb(117, 117, 117);
}
.iris-dropdown .iris-option .iris-option__selected-view {
  display: none;
}
.iris-dropdown .iris-option__multiselect-checkbox-container {
  display: none;
}
.iris-dropdown .iris-options-list {
  display: none;
}
.iris-dropdown .iris-list-item__content {
  width: 100%;
}
.iris-dropdown .iris-dropdown__display-list-container {
  -webkit-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.iris-dropdown .iris-dropdown__display-list-container, .iris-dropdown.iris-dropdown--collapsed .iris-dropdown__display-list-container {
  display: none;
}
.iris-dropdown.iris-dropdown--expanding .iris-dropdown__display-list-container, .iris-dropdown.iris-dropdown--collapsing .iris-dropdown__display-list-container, .iris-dropdown.iris-dropdown--expanded .iris-dropdown__display-list-container {
  display: block;
}
.iris-dropdown.iris-dropdown--expanding .iris-dropdown__selected-value, .iris-dropdown.iris-dropdown--collapsing .iris-dropdown__selected-value, .iris-dropdown.iris-dropdown--expanded .iris-dropdown__selected-value {
  -webkit-box-shadow: 0 0 4px 2px rgb(2, 103, 193);
          box-shadow: 0 0 4px 2px rgb(2, 103, 193);
  outline: none;
}

.iris-dropdown--error .iris-dropdown__selected-value {
  border-color: rgb(206, 22, 22);
}
.iris-dropdown--error .iris-dropdown__capture-input:focus + .iris-dropdown__selected-value, .iris-dropdown--error .iris-dropdown__capture-input:active + .iris-dropdown__selected-value {
  -webkit-box-shadow: 0 0 4px 2px rgb(206, 22, 22);
          box-shadow: 0 0 4px 2px rgb(206, 22, 22);
}
.iris-dropdown--error.iris-dropdown--expanded .iris-dropdown__selected-value {
  -webkit-box-shadow: 0 0 4px 2px rgb(206, 22, 22);
          box-shadow: 0 0 4px 2px rgb(206, 22, 22);
}
.iris-dropdown--disabled {
  cursor: not-allowed;
}
.iris-dropdown--disabled .iris-dropdown__selected-value {
  border-color: rgba(117, 117, 117, 0.5);
  color: rgba(51, 51, 51, 0.5);
  pointer-events: none;
}
.iris-dropdown--disabled .iris-dropdown__capture-input:focus + .iris-dropdown__selected-value, .iris-dropdown--disabled .iris-dropdown__capture-input:active + .iris-dropdown__selected-value {
  -webkit-box-shadow: 0 0 4px 2px none;
          box-shadow: 0 0 4px 2px none;
}
.iris-dropdown--disabled .iris-dropdown__placeholder {
  color: rgba(51, 51, 51, 0.5);
}

.iris-dropdown[data-display=inline] {
  display: inline-block;
  width: auto;
}
.iris-dropdown[data-size=small] {
  width: 86px;
}
.iris-dropdown[data-size=medium] {
  width: 145px;
}
.iris-dropdown[data-size=large] {
  width: 305px;
}
.iris-dropdown[multiple] .iris-option__multiselect-checkbox-container {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  padding-left: 10px;
  position: absolute;
  top: 0;
  width: 32px;
}
.iris-dropdown[multiple] .iris-option {
  min-height: 22px;
  padding-left: 42px;
}

.iris-dropdown__display-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.iris-option .iris-option-hidden-content {
  display: none;
}

.iris-dropdown .iris-dropdown__display-list-header {
  display: none;
}
.iris-dropdown .iris-dropdown__display-list-container {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(117, 117, 117);
  border-radius: 3px;
  left: 0;
  max-height: 250px;
  min-width: 100%;
  opacity: 0;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  -webkit-transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: max-height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  z-index: 1;
}
.iris-dropdown.iris-dropdown--expanding .iris-dropdown__display-list-container, .iris-dropdown.iris-dropdown--collapsing .iris-dropdown__display-list-container {
  max-height: 0;
  opacity: 0;
}
.iris-dropdown.iris-dropdown--expanded .iris-dropdown__display-list-container {
  max-height: 250px;
  opacity: 1;
}
.iris-dropdown--dropup .iris-dropdown__display-list-container {
  bottom: 100%;
  top: auto;
}

.iris-empty-state {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-top: 30px;
  width: 100%;
}

.iris-empty-state__inner {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 500px;
  text-align: center;
  width: 100%;
}

.iris-empty-state__title {
  color: rgb(51, 51, 51);
  margin-bottom: 10px;
  margin-top: 0;
}

.iris-empty-state__copy {
  color: rgb(117, 117, 117);
  margin-bottom: 40px;
  margin-top: 0;
}

.iris-form-group {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}
.iris-form-group > .iris-form-label, .iris-form-group > .iris-form-group__label {
  margin: 0;
  padding-bottom: 10px;
}

.iris-form-group__label,
.iris-form-label,
.iris-label {
  color: rgb(117, 117, 117);
  font-size: 1.4rem;
  font-weight: 600;
}

.iris-form-group__note,
.iris-form-note {
  color: rgb(117, 117, 117);
  font-size: 1.2rem;
  font-style: italic;
}

.iris-form-group__field,
.iris-form-inputs {
  padding-bottom: 25px;
  width: 100%;
}

@media (min-width: 883px) {
  .iris-form-group--inline {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .iris-form-group--inline > .iris-form-inputs, .iris-form-group--inline > .iris-form-group__field {
    min-width: 0;
  }
  .iris-form-group--inline > .iris-form-label, .iris-form-group--inline > .iris-form-group__label {
    min-width: 12rem;
    padding: 8px 20px 8px 0;
    text-align: left;
    width: 100%;
  }
  .iris-form-group[data-size] {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .iris-form-group[data-size] > .iris-form-inputs, .iris-form-group[data-size] > .iris-form-group__field {
    width: 100%;
  }
  .iris-form-group[data-size] > .iris-form-label, .iris-form-group[data-size] > .iris-form-group__label {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    min-width: auto;
  }
  .iris-form-group[data-size=small] > .iris-form-label, .iris-form-group[data-size=small] > .iris-form-group__label {
    width: 86px;
  }
  .iris-form-group[data-size=medium] > .iris-form-label, .iris-form-group[data-size=medium] > .iris-form-group__label {
    width: 145px;
  }
  .iris-form-group[data-size=large] > .iris-form-label, .iris-form-group[data-size=large] > .iris-form-group__label {
    width: 305px;
  }
}
.iris-form-groupset {
  border: 0;
  margin: 0;
  padding: 0;
}
.iris-form-groupset .iris-form-groupset__label {
  color: rgb(117, 117, 117);
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.iris-form-groupset .iris-form-groupset__groups {
  width: 100%;
}
.iris-form-groupset .iris-form-groupset__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.iris-form-groupset .iris-radio,
.iris-form-groupset .iris-checkbox {
  padding-bottom: 15px;
}

@media (min-width: 883px) {
  .iris-form-groupset--inline .iris-form-groupset__container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .iris-form-groupset--inline .iris-form-groupset__container > .iris-form-groupset__label {
    min-width: 12rem;
    padding-right: 20px;
    text-align: left;
    width: 100%;
  }
  .iris-form-groupset[data-size=small] .iris-form-groupset__container {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .iris-form-groupset[data-size=small] .iris-form-groupset__container > .iris-form-groupset__groups {
    width: 100%;
  }
  .iris-form-groupset[data-size=small] .iris-form-groupset__container > .iris-form-groupset__label {
    min-width: auto;
    width: 86px;
  }
  .iris-form-groupset[data-size=medium] .iris-form-groupset__container {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .iris-form-groupset[data-size=medium] .iris-form-groupset__container > .iris-form-groupset__groups {
    width: 100%;
  }
  .iris-form-groupset[data-size=medium] .iris-form-groupset__container > .iris-form-groupset__label {
    min-width: auto;
    width: 145px;
  }
  .iris-form-groupset[data-size=large] .iris-form-groupset__container {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .iris-form-groupset[data-size=large] .iris-form-groupset__container > .iris-form-groupset__groups {
    width: 100%;
  }
  .iris-form-groupset[data-size=large] .iris-form-groupset__container > .iris-form-groupset__label {
    min-width: auto;
    width: 305px;
  }
}
.iris-list {
  background: rgb(255, 255, 255);
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.iris-list .iris-list-item .iris-account-tile {
  margin: 0.5rem;
  opacity: 0.75;
}
.iris-list .iris-list-item .iris-tile {
  margin: 0.5rem;
}

.iris-list--actionable .iris-list-item, .iris-list--checkable .iris-list-item {
  cursor: pointer;
}
.iris-list--actionable .iris-list-item:hover .iris-record,
.iris-list--actionable .iris-list-item:hover .iris-tile,
.iris-list--actionable .iris-list-item:hover .iris-list-item__content, .iris-list--checkable .iris-list-item:hover .iris-record,
.iris-list--checkable .iris-list-item:hover .iris-tile,
.iris-list--checkable .iris-list-item:hover .iris-list-item__content {
  background: rgb(242, 242, 242);
  border-color: rgb(230, 230, 230);
}
.iris-list--actionable .iris-list-item:hover .iris-account-tile, .iris-list--checkable .iris-list-item:hover .iris-account-tile {
  -webkit-box-shadow: 0 8px 15px -7px rgba(0, 0, 0, 0.6);
          box-shadow: 0 8px 15px -7px rgba(0, 0, 0, 0.6);
  opacity: 1;
}
.iris-list--actionable .iris-list-item[disabled] .iris-account-tile,
.iris-list--actionable .iris-list-item[disabled] .iris-record,
.iris-list--actionable .iris-list-item[disabled] .iris-tile,
.iris-list--actionable .iris-list-item[disabled] .iris-list-item__content, .iris-list--checkable .iris-list-item[disabled] .iris-account-tile,
.iris-list--checkable .iris-list-item[disabled] .iris-record,
.iris-list--checkable .iris-list-item[disabled] .iris-tile,
.iris-list--checkable .iris-list-item[disabled] .iris-list-item__content {
  opacity: 0.5;
}
.iris-list--actionable .iris-list-item .iris-checkbox, .iris-list--checkable .iris-list-item .iris-checkbox {
  pointer-events: none;
}
.iris-list--actionable .iris-list-item .iris-account-tile::after {
  -webkit-transition: 250ms cubic-bezier(0.5, 0, 0.2, 1);
  transition: 250ms cubic-bezier(0.5, 0, 0.2, 1);
}
.iris-list--actionable .iris-list-item[aria-selected=true] .iris-record,
.iris-list--actionable .iris-list-item[aria-selected=true] .iris-tile,
.iris-list--actionable .iris-list-item[aria-selected=true] .iris-list-item__content {
  background: rgb(242, 242, 242);
  border-color: rgb(230, 230, 230);
}
.iris-list--actionable .iris-list-item[aria-selected=true] .iris-account-tile::after {
  -webkit-transform: translate3d(100%, 0, 0) skew(45deg, 0);
          transform: translate3d(100%, 0, 0) skew(45deg, 0);
}
.iris-keyboard-use-detected .iris-list--checkable .iris-list-item[data-active=true] .iris-record,
.iris-keyboard-use-detected .iris-list--checkable .iris-list-item[data-active=true] .iris-tile,
.iris-keyboard-use-detected .iris-list--checkable .iris-list-item[data-active=true] .iris-list-item__content {
  background: rgb(242, 242, 242);
  border-color: rgb(230, 230, 230);
}
.iris-keyboard-use-detected .iris-list .iris-list-item[data-active=true]:not([aria-selected=true]) .iris-record,
.iris-keyboard-use-detected .iris-list .iris-list-item[data-active=true]:not([aria-selected=true]) .iris-tile,
.iris-keyboard-use-detected .iris-list .iris-list-item[data-active=true]:not([aria-selected=true]) .iris-list-item__content {
  background: rgb(242, 242, 242);
  border-color: rgb(230, 230, 230);
}
.iris-keyboard-use-detected .iris-list .iris-list-item[data-active=true]:not([aria-selected=true]) .iris-account-tile {
  -webkit-box-shadow: 0 8px 15px -7px rgba(0, 0, 0, 0.6);
          box-shadow: 0 8px 15px -7px rgba(0, 0, 0, 0.6);
  opacity: 1;
}

.iris-loading {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  width: 16px;
}
.iris-loading::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.iris-loading[data-size=small] {
  width: 16px !important;
}
.iris-loading[data-size=small] .iris-loading-path {
  stroke-width: 8;
}
.iris-loading[data-size=medium] {
  width: 32px !important;
}
.iris-loading[data-size=medium] .iris-loading-path {
  stroke-width: 6;
}
.iris-loading[data-size=large] {
  width: 64px !important;
}
.iris-loading[data-size=large] .iris-loading-path {
  stroke-width: 4;
}

.iris-loading-svg {
  -webkit-animation: iris-loading-rotate 2s linear infinite;
          animation: iris-loading-rotate 2s linear infinite;
  height: 100%;
  inset: 0;
  margin: auto;
  position: absolute;
  stroke: currentcolor;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
}

.iris-loading-path {
  -webkit-animation: iris-loading-dash 1.5s ease-in-out infinite;
          animation: iris-loading-dash 1.5s ease-in-out infinite;
  fill: none;
  stroke-dasharray: 100, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 4;
}

.iris-loading.font-color--theme-primary.font-color--theme-secondary .iris-loading-path {
  -webkit-animation: iris-loading-dash 1.5s ease-in-out infinite, iris-loading-theme-colors 3s ease-in-out infinite;
          animation: iris-loading-dash 1.5s ease-in-out infinite, iris-loading-theme-colors 3s ease-in-out infinite;
}

@-webkit-keyframes iris-loading-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes iris-loading-rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes iris-loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes iris-loading-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@-webkit-keyframes iris-loading-theme-colors {
  0%, 100% {
    stroke: var(--font-color--theme-primary);
  }
  50% {
    stroke: var(--font-color--theme-secondary);
  }
}
@keyframes iris-loading-theme-colors {
  0%, 100% {
    stroke: var(--font-color--theme-primary);
  }
  50% {
    stroke: var(--font-color--theme-secondary);
  }
}
.iris-marquee {
  background: rgb(230, 230, 230);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.iris-marquee__row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.iris-marquee__row:first-child {
  padding: 20px 15px;
}
.iris-marquee__row:only-child {
  border-bottom: 1px solid rgb(230, 230, 230);
}

.iris-marquee__action-group {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.iris-marquee__title {
  margin: 0;
}

.iris-notification .iris-notification__context {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.iris-notification--breadbox {
  background-color: rgb(255, 255, 255);
  border: 1px solid;
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.iris-notification--breadbox .iris-notification__context {
  color: rgb(255, 255, 255);
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 1.6rem;
  padding: 2.2rem 1.2rem;
}
.iris-notification--breadbox .iris-notification__context [class*=font-icon-]::before,
.iris-notification--breadbox .iris-notification__context [class*=font-icon-]::after {
  font-size: 1.6rem;
}
.iris-notification--breadbox .iris-notification__message {
  color: rgb(51, 51, 51);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
  -ms-flex-negative: 2;
      flex-shrink: 2;
  font-size: 1.4rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1.6rem;
  margin: 0;
  padding: 1rem 1.5rem;
  width: 100%;
}
.iris-notification--breadbox .iris-notification__action {
  -ms-flex-item-align: start;
      align-self: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding: 1rem 1.5rem 1rem 0;
}
.iris-notification--baguette {
  width: 100%;
}
.iris-notification--baguette .iris-notification {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.iris-notification--baguette .iris-notification__inner {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  max-width: 932px;
  padding: 30px;
  width: 100%;
}
.iris-notification--baguette .iris-notification__context {
  color: rgb(255, 255, 255);
  font-size: 3.8rem;
  margin-right: 14px;
}
.iris-notification--baguette .iris-notification__context [class*=font-icon-]::before,
.iris-notification--baguette .iris-notification__context [class*=font-icon-]::after {
  font-size: 3.8rem;
}
.iris-notification--baguette .iris-notification__message {
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
  line-height: 1.8rem;
}
.iris-notification--baguette .iris-notification__action-button {
  color: rgb(255, 255, 255);
  font-size: 1.8rem;
  line-height: 1.8rem;
  margin-left: auto;
  padding: 18px 20px;
}
.iris-notification--baguette .iris-notification__action-button:hover {
  color: #e6e6e6;
}
.iris-notification--baguette .iris-notification__action-button:focus {
  background-color: rgb(255, 255, 255);
  color: rgb(51, 51, 51);
}

.iris-notification--negative.iris-notification--breadbox {
  border-color: rgb(206, 22, 22);
}
.iris-notification--negative.iris-notification--breadbox .iris-notification__context {
  background-color: rgb(206, 22, 22);
}
.iris-notification--negative.iris-notification--baguette {
  background-color: rgb(206, 22, 22);
}
.iris-notification--positive.iris-notification--breadbox {
  border-color: rgb(27, 124, 53);
}
.iris-notification--positive.iris-notification--breadbox .iris-notification__context {
  background-color: rgb(27, 124, 53);
}
.iris-notification--positive.iris-notification--baguette {
  background-color: rgb(27, 124, 53);
}
.iris-notification--caution.iris-notification--breadbox {
  border-color: rgb(196, 84, 19);
}
.iris-notification--caution.iris-notification--breadbox .iris-notification__context {
  background-color: rgb(196, 84, 19);
}
.iris-notification--caution.iris-notification--baguette {
  background-color: rgb(196, 84, 19);
}
.iris-notification--info.iris-notification--breadbox {
  border-color: rgb(2, 103, 193);
}
.iris-notification--info.iris-notification--breadbox .iris-notification__context {
  background-color: rgb(2, 103, 193);
}
.iris-notification--info.iris-notification--baguette {
  background-color: rgb(2, 103, 193);
}
.iris-notification--bulletin {
  --colorBrandedBulletin: #FFECB5;
}
.iris-notification--bulletin.iris-notification--breadbox {
  border-color: rgb(255, 236, 181);
  border-color: var(--colorBrandedBulletin);
}
.iris-notification--bulletin.iris-notification--breadbox .iris-notification__context {
  background-color: rgb(255, 236, 181);
  background-color: var(--colorBrandedBulletin);
  color: rgb(51, 51, 51);
}
.iris-notification--bulletin.iris-notification--baguette {
  background-color: rgb(255, 236, 181);
  background-color: var(--colorBrandedBulletin);
}
.iris-notification--bulletin.iris-notification--baguette .iris-notification__context {
  color: rgb(51, 51, 51);
}
.iris-notification--bulletin.iris-notification--baguette .iris-notification__action-button,
.iris-notification--bulletin.iris-notification--baguette .iris-notification__message {
  color: rgb(51, 51, 51);
}
.iris-notification--bulletin.iris-notification--baguette .iris-notification__action-button:hover {
  color: #1a1a1a;
}

.iris-notification[data-modifier=stacked] .iris-notification__inner {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.iris-notification[data-modifier=stacked] .iris-notification__context {
  margin-right: 0;
}
.iris-notification[data-modifier=stacked] .iris-notification__action-button {
  position: absolute;
  right: 10px;
  top: 10px;
}

@media (max-width: 578px) {
  .iris-notification .iris-notification__message {
    font-size: 1.4rem;
    margin: 0;
  }
  .iris-notification--breadbox .iris-notification__context {
    padding: 1.2rem 1rem;
  }
  .iris-notification--breadbox .iris-notification__message {
    padding: 1.2rem 1rem;
  }
  .iris-notification--baguette .iris-notification__inner {
    padding: 10px;
  }
  .iris-notification--baguette .iris-notification__context [class*=font-icon-]::before,
  .iris-notification--baguette .iris-notification__context [class*=font-icon-]::after {
    font-size: 2rem;
  }
  .iris-notification--baguette .iris-notification__action-button {
    border: 0;
    padding: 0;
  }
}
.iris-pagination {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.iris-pagination__textfield {
  width: 55px;
}
.iris-pagination__textfield .iris-textfield__input {
  font-size: 1.6rem;
  padding: 3px;
  text-align: center;
  /* stylelint-disable */
}
.iris-pagination__textfield .iris-textfield__input::-webkit-outer-spin-button, .iris-pagination__textfield .iris-textfield__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.iris-pagination__textfield .iris-textfield__input[type=number] {
  -moz-appearance: textfield;
}

.iris-pagination__dropdown {
  width: 70px;
}
.iris-pagination__dropdown .iris-dropdown__selected-value {
  padding-bottom: 5px;
  padding-top: 5px;
}

.iris-pagination__total {
  color: rgb(117, 117, 117);
  font-size: 1.6rem;
  margin-left: 10px;
  margin-right: 5px;
  white-space: nowrap;
}

.iris-pagination__direction-button {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px;
  color: rgb(117, 117, 117);
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  -webkit-transition: 200ms all linear;
  transition: 200ms all linear;
}
.iris-pagination__direction-button:hover {
  background-color: rgb(242, 242, 242);
  border-color: rgb(117, 117, 117);
  color: rgb(117, 117, 117);
}
.iris-pagination__direction-button:focus {
  -webkit-box-shadow: 0 0 4px 2px rgb(2, 103, 193);
          box-shadow: 0 0 4px 2px rgb(2, 103, 193);
  outline: none;
}
.iris-pagination__direction-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.iris-pagination__direction-button:disabled:hover {
  background-color: transparent;
  border: 1px solid transparent;
  color: rgb(117, 117, 117);
}

.iris-pagination__direction-button--first {
  margin-right: 5px;
}

.iris-pagination__direction-button--previous {
  margin-right: 10px;
}

.iris-pagination__direction-button--next,
.iris-pagination__direction-button--last {
  margin-left: 5px;
}

.iris-popover {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  -webkit-box-shadow: 0 20px 50px -5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 20px 50px -5px rgba(0, 0, 0, 0.3);
  margin: 1rem;
  z-index: 1;
}
.iris-popover .iris-popover__header {
  margin: 1rem;
}
.iris-popover .iris-popover__content {
  color: rgb(117, 117, 117);
  font-size: 1.4rem;
  margin: 1rem;
}
.iris-popover .iris-popover__footer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 2rem 1rem 1rem;
}

.iris-popover--expand-start {
  display: block;
  opacity: 0;
}

.iris-popover--expanding {
  display: block;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.iris-popover--expanded {
  display: block;
}

.iris-popover--collapse-start {
  display: block;
}

.iris-popover--collapsing {
  display: block;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.iris-popover--collapsed {
  display: none;
}

.iris-popover {
  position: relative;
}
.iris-popover::before {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  width: 9px;
}
.iris-popover[x-indicator=none]::before {
  display: none;
}
.iris-popover[x-placement^=top] {
  margin-left: 0;
  margin-right: 0;
}
.iris-popover[x-placement^=top]::before {
  border-bottom: 1px solid rgb(230, 230, 230);
  border-right: 1px solid rgb(230, 230, 230);
  bottom: -5px;
  right: 50%;
}
.iris-popover[x-placement^=top][x-indicator^=start]::before {
  left: 10px;
  right: auto;
}
.iris-popover[x-placement^=top][x-indicator^=end]::before {
  right: 10px;
}
.iris-popover[x-placement^=right] {
  margin-bottom: 0;
  margin-top: 0;
}
.iris-popover[x-placement^=right]::before {
  border-bottom: 1px solid rgb(230, 230, 230);
  border-left: 1px solid rgb(230, 230, 230);
  bottom: 50%;
  left: -5px;
  margin-bottom: -5px;
}
.iris-popover[x-placement^=right][x-indicator^=start]::before {
  bottom: auto;
  top: 10px;
}
.iris-popover[x-placement^=right][x-indicator^=end]::before {
  bottom: 10px;
}
.iris-popover[x-placement^=bottom] {
  margin-left: 0;
  margin-right: 0;
}
.iris-popover[x-placement^=bottom]::before {
  border-left: 1px solid rgb(230, 230, 230);
  border-top: 1px solid rgb(230, 230, 230);
  right: 50%;
  top: -5px;
}
.iris-popover[x-placement^=bottom][x-indicator^=start]::before {
  left: 10px;
  right: auto;
}
.iris-popover[x-placement^=bottom][x-indicator^=end]::before {
  right: 10px;
}
.iris-popover[x-placement^=left] {
  margin-bottom: 0;
  margin-top: 0;
}
.iris-popover[x-placement^=left]::before {
  border-right: 1px solid rgb(230, 230, 230);
  border-top: 1px solid rgb(230, 230, 230);
  bottom: 50%;
  margin-bottom: -5px;
  right: -5px;
}
.iris-popover[x-placement^=left][x-indicator^=start]::before {
  bottom: auto;
  top: 10px;
}
.iris-popover[x-placement^=left][x-indicator^=end]::before {
  bottom: 10px;
}

.iris-popover[data-size=small] {
  width: 260px;
}
.iris-popover[data-size=medium] {
  width: 300px;
}
.iris-popover[data-size=large] {
  width: 500px;
}

.iris-progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}

.iris-progress-bar__title {
  color: rgb(51, 51, 51);
  margin-bottom: 10px;
}

.iris-progress-bar__secondary-info {
  margin-top: 5px;
}

.iris-progress-bar__meter {
  background-color: rgb(230, 230, 230);
  border: none;
  border-radius: 999px;
  display: block;
  height: 10px;
  width: 100%;
}

.iris-progress-bar--info .iris-progress-bar__meter::-ms-fill {
  background-color: rgb(2, 103, 193);
  border-radius: 999px;
}
.iris-progress-bar--info .iris-progress-bar__meter::-moz-progress-bar {
  background-color: rgb(2, 103, 193);
  border-radius: 999px;
}
.iris-progress-bar--info .iris-progress-bar__meter::-webkit-progress-value {
  background-color: rgb(2, 103, 193);
  border-radius: 999px;
}
.iris-progress-bar--info .iris-progress-bar__meter::-webkit-progress-bar {
  background-color: rgb(230, 230, 230);
  border-radius: 999px;
}
.iris-progress-bar--info .iris-progress-bar__secondary-info {
  color: rgb(117, 117, 117);
}
.iris-progress-bar--positive .iris-progress-bar__meter::-ms-fill {
  background-color: rgb(27, 124, 53);
  border-radius: 999px;
}
.iris-progress-bar--positive .iris-progress-bar__meter::-moz-progress-bar {
  background-color: rgb(27, 124, 53);
  border-radius: 999px;
}
.iris-progress-bar--positive .iris-progress-bar__meter::-webkit-progress-value {
  background-color: rgb(27, 124, 53);
  border-radius: 999px;
}
.iris-progress-bar--positive .iris-progress-bar__meter::-webkit-progress-bar {
  background-color: rgb(230, 230, 230);
  border-radius: 999px;
}
.iris-progress-bar--positive .iris-progress-bar__secondary-info {
  color: rgb(27, 124, 53);
}
.iris-progress-bar--negative .iris-progress-bar__meter::-ms-fill {
  background-color: rgb(206, 22, 22);
  border-radius: 999px;
}
.iris-progress-bar--negative .iris-progress-bar__meter::-moz-progress-bar {
  background-color: rgb(206, 22, 22);
  border-radius: 999px;
}
.iris-progress-bar--negative .iris-progress-bar__meter::-webkit-progress-value {
  background-color: rgb(206, 22, 22);
  border-radius: 999px;
}
.iris-progress-bar--negative .iris-progress-bar__meter::-webkit-progress-bar {
  background-color: rgb(230, 230, 230);
  border-radius: 999px;
}
.iris-progress-bar--negative .iris-progress-bar__secondary-info {
  color: rgb(206, 22, 22);
}
.iris-progress-bar--neutral .iris-progress-bar__meter::-ms-fill {
  background-color: rgb(117, 117, 117);
  border-radius: 999px;
}
.iris-progress-bar--neutral .iris-progress-bar__meter::-moz-progress-bar {
  background-color: rgb(117, 117, 117);
  border-radius: 999px;
}
.iris-progress-bar--neutral .iris-progress-bar__meter::-webkit-progress-value {
  background-color: rgb(117, 117, 117);
  border-radius: 999px;
}
.iris-progress-bar--neutral .iris-progress-bar__meter::-webkit-progress-bar {
  background-color: rgb(230, 230, 230);
  border-radius: 999px;
}
.iris-progress-bar--neutral .iris-progress-bar__secondary-info {
  color: rgb(117, 117, 117);
}

.iris-prompt {
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  left: 50%;
  opacity: 0;
  position: fixed;
  top: -100%;
  -webkit-transform: translateY(0) translateX(-50%) scale(0.98, 0.98);
          transform: translateY(0) translateX(-50%) scale(0.98, 0.98);
  -webkit-transition: opacity 600ms ease-out, -webkit-transform 600ms ease-out;
  transition: opacity 600ms ease-out, -webkit-transform 600ms ease-out;
  transition: opacity 600ms ease-out, transform 600ms ease-out;
  transition: opacity 600ms ease-out, transform 600ms ease-out, -webkit-transform 600ms ease-out;
  width: 500px;
  z-index: -1;
}

.iris-prompt__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
  max-height: 80vh;
}

.iris-prompt__header {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 30px;
}
.iris-prompt__header + .iris-prompt__body {
  padding-top: 0;
}

.iris-prompt__title {
  margin: 0;
}

.iris-prompt__body {
  -webkit-box-flex: 2;
      -ms-flex: 2 2 auto;
          flex: 2 2 auto;
  font-size: 1.6rem;
  overflow: auto;
  padding: 30px;
  padding-bottom: 0;
  -webkit-transition: 400ms all ease-out;
  transition: 400ms all ease-out;
}

.iris-prompt__footer {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 0 0 4px 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 30px;
}

.iris-prompt--open {
  opacity: 1;
  top: 10vh;
  -webkit-transform: translateY(0) translateX(-50%) scale(1, 1);
          transform: translateY(0) translateX(-50%) scale(1, 1);
  z-index: 1000;
}
.iris-prompt--closing {
  top: 10vh;
  -webkit-transform: translateY(0) translateX(-50%) scale(0.98, 0.98);
          transform: translateY(0) translateX(-50%) scale(0.98, 0.98);
  z-index: 1000;
}

.iris-prompt[data-size=narrow], .iris-prompt[data-size=small] {
  width: 350px;
}
.iris-prompt[data-size=wide], .iris-prompt[data-size=medium] {
  width: 500px;
}
.iris-prompt[data-size=large] {
  width: 620px;
}
.iris-prompt[data-size=xlarge] {
  width: 900px;
}

.iris-radio__input {
  height: 1px;
  opacity: 0;
  position: absolute;
  width: 1px;
}
.iris-radio__input:checked ~ .iris-radio__label::before {
  border-color: rgb(117, 117, 117);
}
.iris-radio__input:checked ~ .iris-radio__label::after {
  -webkit-transform: translateY(-50%) scale(1, 1);
          transform: translateY(-50%) scale(1, 1);
}
.iris-radio__input:focus ~ .iris-radio__label::before {
  border-color: rgb(2, 103, 193);
  -webkit-box-shadow: 0 0 4px 2px rgb(2, 103, 193);
          box-shadow: 0 0 4px 2px rgb(2, 103, 193);
}

.iris-radio__label {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.4rem;
  font-weight: 600;
  position: relative;
}
.iris-radio__label::before {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(117, 117, 117);
  border-radius: 100%;
  content: "";
  display: block;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: 24px;
  -webkit-transition: 200ms all linear;
  transition: 200ms all linear;
  width: 24px;
}
.iris-radio__label::after {
  background: rgb(2, 103, 193);
  border-radius: 100%;
  content: "";
  display: block;
  height: 12px;
  left: 6px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0, 0);
          transform: translate(-50%, -50%) scale(0, 0);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.8, 0.1, 0.2, 1.5);
  transition: -webkit-transform 0.2s cubic-bezier(0.8, 0.1, 0.2, 1.5);
  transition: transform 0.2s cubic-bezier(0.8, 0.1, 0.2, 1.5);
  transition: transform 0.2s cubic-bezier(0.8, 0.1, 0.2, 1.5), -webkit-transform 0.2s cubic-bezier(0.8, 0.1, 0.2, 1.5);
  width: 12px;
}
.iris-radio__label:hover::before {
  border-color: rgb(51, 51, 51);
}

.iris-radio__content {
  margin-left: 10px;
}

.iris-radio__extended-content {
  display: block;
  margin-left: 34px;
  margin-top: 5px;
}

.iris-radio__description {
  color: rgb(117, 117, 117);
  display: inline-block;
  font-size: 1.4rem;
}

.iris-radio--disabled .iris-radio__input ~ .iris-radio__label,
.iris-radio .iris-radio__input:disabled ~ .iris-radio__label {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
.iris-radio--disabled .iris-radio__input ~ .iris-radio__label:hover::before,
.iris-radio .iris-radio__input:disabled ~ .iris-radio__label:hover::before {
  border-color: rgb(117, 117, 117);
}
.iris-radio--disabled .iris-radio__input:focus ~ .iris-radio__label::before,
.iris-radio .iris-radio__input:disabled:focus ~ .iris-radio__label::before {
  border-color: rgb(117, 117, 117);
  -webkit-box-shadow: none;
          box-shadow: none;
}

.iris-record {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 15px 0;
  width: 100%;
}
.iris-record:not(:last-child), .iris-list-item:not(:last-child) .iris-record {
  border-bottom: 1px solid rgb(230, 230, 230);
}

.iris-record--collapsable {
  border: 1px solid transparent;
  -webkit-transition: margin 0.2s ease-in-out;
  transition: margin 0.2s ease-in-out;
}
.iris-record--collapsable:not(:last-child) {
  border-bottom: 1px solid rgb(230, 230, 230);
}
.iris-record--collapsable:focus, .iris-record--collapsable:hover {
  border: 1px solid rgb(230, 230, 230);
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
}
.iris-record--collapsable.iris-record--collapsable-expanded {
  border: 1px solid rgb(230, 230, 230);
  -webkit-box-shadow: 0 0 50px -5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 50px -5px rgba(0, 0, 0, 0.3);
  margin: 15px 0;
}
.iris-record--collapsable.iris-record--collapsable-expanded + .iris-record {
  border-top: 1px solid rgb(230, 230, 230);
}

.iris-record[data-spacing=compact] {
  padding: 10px 0;
}
.iris-record[data-spacing=cozy] {
  padding: 20px 0;
}
.iris-record[data-spacing=comfortable] {
  padding: 30px 0;
}

.striped .iris-record:nth-of-type(odd),
.zebra .iris-record:nth-of-type(odd) {
  background-color: rgb(242, 242, 242);
}

.iris-switchboard {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 3.5rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 35px;
  position: relative;
}

.iris-switchboard__input {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.iris-switchboard__label {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: transparent;
  border: none;
  border-radius: 3.5rem;
  color: rgb(117, 117, 117);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.4rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1.4rem;
  padding: 12px 15px;
  position: relative;
  -webkit-transition: 0.15s;
  transition: 0.15s;
  z-index: 1;
}
.iris-switchboard__label::before {
  border-radius: 3.5rem;
  -webkit-box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.3);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  -webkit-transition: 0.25s;
  transition: 0.25s;
  width: 100%;
  z-index: -1;
}
.iris-switchboard__label .fa {
  margin-right: 10px;
}

.iris-switchboard__input:checked + .iris-switchboard__label::before {
  opacity: 1;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.iris-switchboard__input:focus:not(:checked) + .iris-switchboard__label::before,
.iris-switchboard__input:active:not(:checked) + .iris-switchboard__label::before {
  -webkit-box-shadow: 0 0 4px 2px rgb(2, 103, 193);
          box-shadow: 0 0 4px 2px rgb(2, 103, 193);
  opacity: 1;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.iris-switchboard__input:focus:checked + .iris-switchboard__label::before,
.iris-switchboard__input:active:checked + .iris-switchboard__label::before {
  -webkit-box-shadow: 0 0 4px 2px rgb(2, 103, 193);
          box-shadow: 0 0 4px 2px rgb(2, 103, 193);
}

.iris-tabs {
  position: relative;
  width: 100%;
}
.iris-tabs .iris-tabs__inner {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}
.iris-tabs .iris-tabs__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.iris-tabs .iris-tabs__list-item {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  list-style: none;
  position: relative;
}
.iris-tabs .iris-tabs__link {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  text-decoration: none;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
.iris-tabs .iris-tabs__link-icon {
  padding-right: 5px;
}
.iris-tabs .iris-tabs__nav-button {
  color: rgb(117, 117, 117);
  display: none;
  line-height: 1.1rem;
  opacity: 0;
  padding: 10px 8px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 10;
}
.iris-tabs .iris-tabs__nav-button .iris-button__icon {
  font-size: 1.1rem;
  line-height: 1.1rem;
}
.iris-tabs .iris-tabs__nav-button:hover {
  background-color: rgb(230, 230, 230);
  color: #5c5c5c;
}
.iris-tabs .iris-tabs__next-button {
  right: 0;
}
.iris-tabs .iris-tabs__back-button {
  left: 0;
}
.iris-tabs::after, .iris-tabs::before {
  content: "";
  display: none;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 105px;
  z-index: 1;
}
.iris-tabs::after {
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgb(255, 255, 255)));
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  right: 0;
}
.iris-tabs::before {
  background: -webkit-gradient(linear, left top, right top, from(rgb(255, 255, 255)), to(rgba(255, 255, 255, 0)));
  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
  left: 0;
}
.iris-tabs.scrollable-right::after {
  opacity: 1;
}
.iris-tabs.scrollable-right .iris-tabs__next-button {
  opacity: 1;
  pointer-events: auto;
}
.iris-tabs.scrollable-left::before {
  opacity: 1;
}
.iris-tabs.scrollable-left .iris-tabs__back-button {
  opacity: 1;
  pointer-events: auto;
}

.iris-textfield {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  width: 100%;
}

.iris-textfield__input {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(117, 117, 117);
  border-radius: 3px;
  color: rgb(51, 51, 51);
  display: block;
  font-size: 1.4rem;
  line-height: 1.25;
  margin: 0;
  padding: 10px;
  -webkit-transition: 200ms all linear;
  transition: 200ms all linear;
  width: 100%;
}
.iris-textfield__input:focus, .iris-textfield__input:active {
  -webkit-box-shadow: 0 0 4px 2px rgb(2, 103, 193);
          box-shadow: 0 0 4px 2px rgb(2, 103, 193);
  outline: none;
}
.iris-textfield__input::-webkit-input-placeholder {
  color: rgb(117, 117, 117);
  opacity: 1;
}
.iris-textfield__input::-moz-placeholder {
  color: rgb(117, 117, 117);
  opacity: 1;
}
.iris-textfield__input:-ms-input-placeholder {
  color: rgb(117, 117, 117);
  opacity: 1;
}
.iris-textfield__input::-ms-input-placeholder {
  color: rgb(117, 117, 117);
  opacity: 1;
}
.iris-textfield__input::placeholder {
  color: rgb(117, 117, 117);
  opacity: 1;
}
.iris-textfield__input::-ms-clear {
  display: none;
}

/* stylelint-disable */
textarea.iris-textfield__input {
  height: 15rem;
  line-height: 1.6rem;
  resize: vertical;
}

select.iris-textfield__input {
  height: 39px;
}

/* stylelint-enable */
.iris-textfield__icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: rgb(255, 255, 255);
  border: 0;
  border-radius: 0;
  color: rgb(51, 51, 51);
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin: 0;
  min-height: 22px;
  padding: 0;
  position: absolute;
  top: calc(50% - 11px);
}

.iris-error-validation .iris-textfield__input, .iris-textfield--error .iris-textfield__input {
  border-color: rgb(206, 22, 22);
}
.iris-error-validation .iris-textfield__input:focus, .iris-textfield--error .iris-textfield__input:focus, .iris-error-validation .iris-textfield__input:active, .iris-textfield--error .iris-textfield__input:active {
  -webkit-box-shadow: 0 0 4px 2px rgb(206, 22, 22);
          box-shadow: 0 0 4px 2px rgb(206, 22, 22);
}

.iris-textfield--disabled .iris-textfield__input {
  border-color: rgba(117, 117, 117, 0.5);
  color: rgba(51, 51, 51, 0.5);
  cursor: not-allowed;
  pointer-events: none;
}
.iris-textfield--disabled .iris-textfield__input:focus, .iris-textfield--disabled .iris-textfield__input:active {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.iris-textfield--disabled .iris-textfield__input::-webkit-input-placeholder, .iris-textfield--disabled .iris-textfield__input:-moz-placeholder, .iris-textfield--disabled .iris-textfield__input::-moz-placeholder, .iris-textfield--disabled .iris-textfield__input:-ms-input-placeholder {
  color: rgba(51, 51, 51, 0.5);
}
.iris-textfield--readonly .iris-textfield__input {
  background-color: transparent;
  border-color: transparent;
  color: rgb(51, 51, 51);
  cursor: text;
  pointer-events: none;
}
.iris-textfield--readonly .iris-textfield__input:focus, .iris-textfield--readonly .iris-textfield__input:active {
  border-color: transparent;
}

.iris-textfield[data-display=inline] {
  display: inline-block;
  width: auto;
}
.iris-textfield[data-size=small] {
  width: 86px;
}
.iris-textfield[data-size=medium] {
  width: 145px;
}
.iris-textfield[data-size=large] {
  width: 305px;
}
.iris-textfield[data-modifier=compressed] .iris-textfield__input {
  line-height: 1.3;
  padding: 4px 10px;
}
.iris-textfield .iris-textfield__icon + .iris-textfield__input {
  padding-left: 35px;
}
.iris-textfield .iris-textfield__icon:first-child {
  left: 10px;
}
.iris-textfield[data-icon-position=leading] .iris-textfield__icon {
  left: 10px;
  right: auto;
}
.iris-textfield[data-icon-position=leading] .iris-textfield__input {
  padding-left: 35px;
  padding-right: 10px;
}
.iris-textfield[data-icon-position=trailing] .iris-textfield__icon {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  left: auto;
  right: 10px;
}
.iris-textfield[data-icon-position=trailing] .iris-textfield__input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding-left: 10px;
  padding-right: 35px;
}

.iris-tile {
  border: transparent 1px solid;
  border-radius: 3px;
  padding: 1rem;
}

.iris-tile--shaded {
  background-color: rgb(242, 242, 242);
  border-color: rgb(230, 230, 230);
}

.iris-toggle__input {
  height: 1px;
  opacity: 0;
  position: absolute;
  width: 1px;
}
.iris-toggle__input:checked ~ .iris-toggle__label .iris-toggle__inner {
  background-color: rgb(2, 103, 193);
  border-color: #3D6AA3;
}
.iris-toggle__input:checked ~ .iris-toggle__label .iris-toggle__inner::before {
  opacity: 1;
}
.iris-toggle__input:checked ~ .iris-toggle__label .iris-toggle__inner::after {
  background-color: rgb(255, 255, 255);
  -webkit-transform: translateY(-50%) translateX(100%) translateX(-2px);
          transform: translateY(-50%) translateX(100%) translateX(-2px);
}
.iris-toggle__input:checked ~ .iris-toggle__label .iris-toggle__left {
  color: rgb(117, 117, 117);
  font-weight: 400;
}
.iris-toggle__input:checked ~ .iris-toggle__label .iris-toggle__right {
  color: rgb(2, 103, 193);
  font-weight: 400;
}
.iris-toggle__input:focus ~ .iris-toggle__label .iris-toggle__inner {
  -webkit-box-shadow: 0 0 4px 2px rgb(2, 103, 193);
          box-shadow: 0 0 4px 2px rgb(2, 103, 193);
}

.iris-toggle__label {
  cursor: pointer;
  display: inline-block;
}

.iris-toggle__left,
.iris-toggle__right {
  display: inline-block;
  text-transform: uppercase;
  -webkit-transition: 200ms all ease-in-out;
  transition: 200ms all ease-in-out;
  vertical-align: middle;
}

.iris-toggle__left {
  color: rgb(51, 51, 51);
  font-weight: 400;
}

.iris-toggle__right {
  color: rgb(117, 117, 117);
  font-weight: 400;
}

.iris-toggle__inner {
  background-color: rgb(230, 230, 230);
  border: 0.5px solid rgb(230, 230, 230);
  border-radius: 1.5rem;
  display: inline-block;
  margin: 0 10px;
  padding: 1.1rem 2rem;
  position: relative;
  -webkit-transition: 200ms all ease-in-out;
  transition: 200ms all ease-in-out;
  vertical-align: middle;
}
.iris-toggle__inner::before {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgb(2, 103, 193)), to(#3F80D2));
  background-image: linear-gradient(to bottom, rgb(2, 103, 193), #3F80D2);
  border-radius: 1.5rem;
  content: "";
  display: block;
  left: 0;
  opacity: 0;
  padding: 1.1rem 2rem;
  position: absolute;
  top: 0;
  -webkit-transition: 200ms all ease-in-out;
  transition: 200ms all ease-in-out;
}
.iris-toggle__inner::after {
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  height: 2rem;
  left: 1px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: 200ms all ease-in-out;
  transition: 200ms all ease-in-out;
  width: 2rem;
}

.iris-toggle--disabled {
  cursor: not-allowed;
}
.iris-toggle--disabled .iris-toggle__input ~ .iris-toggle__label,
.iris-toggle .iris-toggle__input:disabled ~ .iris-toggle__label {
  opacity: 0.35;
  pointer-events: none;
}
.iris-toggle--disabled .iris-toggle__input ~ .iris-toggle__label .iris-toggle__left,
.iris-toggle--disabled .iris-toggle__input ~ .iris-toggle__label .iris-toggle__right,
.iris-toggle .iris-toggle__input:disabled ~ .iris-toggle__label .iris-toggle__left,
.iris-toggle .iris-toggle__input:disabled ~ .iris-toggle__label .iris-toggle__right {
  color: rgb(51, 51, 51);
}
.iris-toggle--disabled .iris-toggle__input:focus ~ .iris-toggle__label .iris-toggle__inner,
.iris-toggle .iris-toggle__input:disabled:focus ~ .iris-toggle__label .iris-toggle__inner {
  border-color: rgb(230, 230, 230);
  -webkit-box-shadow: 0 0 4px 2px transparent;
          box-shadow: 0 0 4px 2px transparent;
}

.iris-tooltip {
  background: rgb(51, 51, 51);
  border: 1px solid rgb(51, 51, 51);
  border-radius: 5px;
  -webkit-box-shadow: 0 20px 50px -5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 20px 50px -5px rgba(0, 0, 0, 0.3);
  color: rgb(255, 255, 255);
  font-size: 1.4rem;
  margin: 1rem;
  max-width: 95%;
  padding: 1rem;
  z-index: 1100;
}

.iris-tooltip__content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.iris-tooltip--expand-start {
  display: block;
  opacity: 0;
}

.iris-tooltip--expanding {
  display: block;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.iris-tooltip--expanded {
  display: block;
}

.iris-tooltip--collapse-start {
  display: block;
}

.iris-tooltip--collapsing {
  display: block;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.iris-tooltip--collapsed {
  display: none;
}

.iris-tooltip {
  position: relative;
}
.iris-tooltip::before {
  background: rgb(51, 51, 51);
  border: 1px solid rgb(51, 51, 51);
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  -webkit-transform: translateX(50%) rotate(45deg);
          transform: translateX(50%) rotate(45deg);
  width: 9px;
}
.iris-tooltip[x-indicator=none]::before {
  display: none;
}
.iris-tooltip[x-placement^=top] {
  margin-left: 0;
  margin-right: 0;
}
.iris-tooltip[x-placement^=top]::before {
  border-bottom: 1px solid rgb(51, 51, 51);
  border-right: 1px solid rgb(51, 51, 51);
  bottom: -5px;
  right: 50%;
}
.iris-tooltip[x-placement^=top][x-indicator^=start]::before {
  left: 10px;
  right: auto;
}
.iris-tooltip[x-placement^=top][x-indicator^=end] {
  text-align: right;
}
.iris-tooltip[x-placement^=top][x-indicator^=end]::before {
  right: 10px;
}
.iris-tooltip[x-placement^=right] {
  margin-bottom: 0;
  margin-top: 0;
}
.iris-tooltip[x-placement^=right]::before {
  border-bottom: 1px solid rgb(51, 51, 51);
  border-left: 1px solid rgb(51, 51, 51);
  bottom: 50%;
  left: -5px;
  margin-bottom: -5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.iris-tooltip[x-placement^=right][x-indicator^=start]::before {
  bottom: auto;
  top: 10px;
}
.iris-tooltip[x-placement^=right][x-indicator^=end]::before {
  bottom: 10px;
}
.iris-tooltip[x-placement^=bottom] {
  margin-left: 0;
  margin-right: 0;
}
.iris-tooltip[x-placement^=bottom]::before {
  border-left: 1px solid rgb(51, 51, 51);
  border-top: 1px solid rgb(51, 51, 51);
  right: 50%;
  top: -5px;
}
.iris-tooltip[x-placement^=bottom][x-indicator^=start]::before {
  left: 10px;
  right: auto;
}
.iris-tooltip[x-placement^=bottom][x-indicator^=end] {
  text-align: right;
}
.iris-tooltip[x-placement^=bottom][x-indicator^=end]::before {
  right: 10px;
}
.iris-tooltip[x-placement^=left] {
  margin-bottom: 0;
  margin-top: 0;
}
.iris-tooltip[x-placement^=left]::before {
  border-right: 1px solid rgb(51, 51, 51);
  border-top: 1px solid rgb(51, 51, 51);
  bottom: 50%;
  margin-bottom: -5px;
  right: -5px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.iris-tooltip[x-placement^=left][x-indicator^=start]::before {
  bottom: auto;
  top: 10px;
}
.iris-tooltip[x-placement^=left][x-indicator^=end]::before {
  bottom: 10px;
}

.iris-tooltip:not([x-size=auto]) .iris-tooltip__content {
  white-space: normal;
}
.iris-tooltip[x-size=small] {
  width: 260px;
}
.iris-tooltip[x-size=medium] {
  width: 300px;
}
.iris-tooltip[x-size=large] {
  width: 500px;
}

.bg-color--caution {
  --bg-color--caution: rgb(196, 84, 19);
  background-color: rgb(196, 84, 19) !important;
}

.bg-color--dark {
  --bg-color--dark: rgb(26, 26, 26);
  background-color: rgb(26, 26, 26) !important;
}

.bg-color--info {
  --bg-color--info: rgb(2, 103, 193);
  background-color: rgb(2, 103, 193) !important;
}

.bg-color--light {
  --bg-color--light: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255) !important;
}

.bg-color--negative {
  --bg-color--negative: rgb(206, 22, 22);
  background-color: rgb(206, 22, 22) !important;
}

.bg-color--neutral {
  --bg-color--neutral: rgb(117, 117, 117);
  background-color: rgb(117, 117, 117) !important;
}

.bg-color--neutral-1 {
  --bg-color--neutral-1: rgb(51, 51, 51);
  background-color: rgb(51, 51, 51) !important;
}

.bg-color--neutral-2 {
  --bg-color--neutral-2: rgb(117, 117, 117);
  background-color: rgb(117, 117, 117) !important;
}

.bg-color--neutral-3 {
  --bg-color--neutral-3: rgb(204, 204, 204);
  background-color: rgb(204, 204, 204) !important;
}

.bg-color--neutral-4 {
  --bg-color--neutral-4: rgb(230, 230, 230);
  background-color: rgb(230, 230, 230) !important;
}

.bg-color--neutral-5 {
  --bg-color--neutral-5: rgb(242, 242, 242);
  background-color: rgb(242, 242, 242) !important;
}

.bg-color--positive {
  --bg-color--positive: rgb(27, 124, 53);
  background-color: rgb(27, 124, 53) !important;
}

.bg-color--none {
  background-color: transparent !important;
}

.border-color--caution {
  --border-color--caution: rgb(196, 84, 19);
  border-color: rgb(196, 84, 19) !important;
}

.border-color--dark {
  --border-color--dark: rgb(26, 26, 26);
  border-color: rgb(26, 26, 26) !important;
}

.border-color--info {
  --border-color--info: rgb(2, 103, 193);
  border-color: rgb(2, 103, 193) !important;
}

.border-color--light {
  --border-color--light: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255) !important;
}

.border-color--negative {
  --border-color--negative: rgb(206, 22, 22);
  border-color: rgb(206, 22, 22) !important;
}

.border-color--neutral {
  --border-color--neutral: rgb(117, 117, 117);
  border-color: rgb(117, 117, 117) !important;
}

.border-color--neutral-1 {
  --border-color--neutral-1: rgb(51, 51, 51);
  border-color: rgb(51, 51, 51) !important;
}

.border-color--neutral-2 {
  --border-color--neutral-2: rgb(117, 117, 117);
  border-color: rgb(117, 117, 117) !important;
}

.border-color--neutral-3 {
  --border-color--neutral-3: rgb(204, 204, 204);
  border-color: rgb(204, 204, 204) !important;
}

.border-color--neutral-4 {
  --border-color--neutral-4: rgb(230, 230, 230);
  border-color: rgb(230, 230, 230) !important;
}

.border-color--neutral-5 {
  --border-color--neutral-5: rgb(242, 242, 242);
  border-color: rgb(242, 242, 242) !important;
}

.border-color--positive {
  --border-color--positive: rgb(27, 124, 53);
  border-color: rgb(27, 124, 53) !important;
}

.border-color--none {
  border-color: transparent !important;
}

.border-top-color--caution {
  --border-top-color--caution: rgb(196, 84, 19);
  border-top-color: rgb(196, 84, 19) !important;
}

.border-top-color--dark {
  --border-top-color--dark: rgb(26, 26, 26);
  border-top-color: rgb(26, 26, 26) !important;
}

.border-top-color--info {
  --border-top-color--info: rgb(2, 103, 193);
  border-top-color: rgb(2, 103, 193) !important;
}

.border-top-color--light {
  --border-top-color--light: rgb(255, 255, 255);
  border-top-color: rgb(255, 255, 255) !important;
}

.border-top-color--negative {
  --border-top-color--negative: rgb(206, 22, 22);
  border-top-color: rgb(206, 22, 22) !important;
}

.border-top-color--neutral {
  --border-top-color--neutral: rgb(117, 117, 117);
  border-top-color: rgb(117, 117, 117) !important;
}

.border-top-color--neutral-1 {
  --border-top-color--neutral-1: rgb(51, 51, 51);
  border-top-color: rgb(51, 51, 51) !important;
}

.border-top-color--neutral-2 {
  --border-top-color--neutral-2: rgb(117, 117, 117);
  border-top-color: rgb(117, 117, 117) !important;
}

.border-top-color--neutral-3 {
  --border-top-color--neutral-3: rgb(204, 204, 204);
  border-top-color: rgb(204, 204, 204) !important;
}

.border-top-color--neutral-4 {
  --border-top-color--neutral-4: rgb(230, 230, 230);
  border-top-color: rgb(230, 230, 230) !important;
}

.border-top-color--neutral-5 {
  --border-top-color--neutral-5: rgb(242, 242, 242);
  border-top-color: rgb(242, 242, 242) !important;
}

.border-top-color--positive {
  --border-top-color--positive: rgb(27, 124, 53);
  border-top-color: rgb(27, 124, 53) !important;
}

.border-top-color--none {
  border-top-color: transparent !important;
}

.border-right-color--caution {
  --border-right-color--caution: rgb(196, 84, 19);
  border-right-color: rgb(196, 84, 19) !important;
}

.border-right-color--dark {
  --border-right-color--dark: rgb(26, 26, 26);
  border-right-color: rgb(26, 26, 26) !important;
}

.border-right-color--info {
  --border-right-color--info: rgb(2, 103, 193);
  border-right-color: rgb(2, 103, 193) !important;
}

.border-right-color--light {
  --border-right-color--light: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255) !important;
}

.border-right-color--negative {
  --border-right-color--negative: rgb(206, 22, 22);
  border-right-color: rgb(206, 22, 22) !important;
}

.border-right-color--neutral {
  --border-right-color--neutral: rgb(117, 117, 117);
  border-right-color: rgb(117, 117, 117) !important;
}

.border-right-color--neutral-1 {
  --border-right-color--neutral-1: rgb(51, 51, 51);
  border-right-color: rgb(51, 51, 51) !important;
}

.border-right-color--neutral-2 {
  --border-right-color--neutral-2: rgb(117, 117, 117);
  border-right-color: rgb(117, 117, 117) !important;
}

.border-right-color--neutral-3 {
  --border-right-color--neutral-3: rgb(204, 204, 204);
  border-right-color: rgb(204, 204, 204) !important;
}

.border-right-color--neutral-4 {
  --border-right-color--neutral-4: rgb(230, 230, 230);
  border-right-color: rgb(230, 230, 230) !important;
}

.border-right-color--neutral-5 {
  --border-right-color--neutral-5: rgb(242, 242, 242);
  border-right-color: rgb(242, 242, 242) !important;
}

.border-right-color--positive {
  --border-right-color--positive: rgb(27, 124, 53);
  border-right-color: rgb(27, 124, 53) !important;
}

.border-right-color--none {
  border-right-color: transparent !important;
}

.border-bottom-color--caution {
  --border-bottom-color--caution: rgb(196, 84, 19);
  border-bottom-color: rgb(196, 84, 19) !important;
}

.border-bottom-color--dark {
  --border-bottom-color--dark: rgb(26, 26, 26);
  border-bottom-color: rgb(26, 26, 26) !important;
}

.border-bottom-color--info {
  --border-bottom-color--info: rgb(2, 103, 193);
  border-bottom-color: rgb(2, 103, 193) !important;
}

.border-bottom-color--light {
  --border-bottom-color--light: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255) !important;
}

.border-bottom-color--negative {
  --border-bottom-color--negative: rgb(206, 22, 22);
  border-bottom-color: rgb(206, 22, 22) !important;
}

.border-bottom-color--neutral {
  --border-bottom-color--neutral: rgb(117, 117, 117);
  border-bottom-color: rgb(117, 117, 117) !important;
}

.border-bottom-color--neutral-1 {
  --border-bottom-color--neutral-1: rgb(51, 51, 51);
  border-bottom-color: rgb(51, 51, 51) !important;
}

.border-bottom-color--neutral-2 {
  --border-bottom-color--neutral-2: rgb(117, 117, 117);
  border-bottom-color: rgb(117, 117, 117) !important;
}

.border-bottom-color--neutral-3 {
  --border-bottom-color--neutral-3: rgb(204, 204, 204);
  border-bottom-color: rgb(204, 204, 204) !important;
}

.border-bottom-color--neutral-4 {
  --border-bottom-color--neutral-4: rgb(230, 230, 230);
  border-bottom-color: rgb(230, 230, 230) !important;
}

.border-bottom-color--neutral-5 {
  --border-bottom-color--neutral-5: rgb(242, 242, 242);
  border-bottom-color: rgb(242, 242, 242) !important;
}

.border-bottom-color--positive {
  --border-bottom-color--positive: rgb(27, 124, 53);
  border-bottom-color: rgb(27, 124, 53) !important;
}

.border-bottom-color--none {
  border-bottom-color: transparent !important;
}

.border-left-color--caution {
  --border-left-color--caution: rgb(196, 84, 19);
  border-left-color: rgb(196, 84, 19) !important;
}

.border-left-color--dark {
  --border-left-color--dark: rgb(26, 26, 26);
  border-left-color: rgb(26, 26, 26) !important;
}

.border-left-color--info {
  --border-left-color--info: rgb(2, 103, 193);
  border-left-color: rgb(2, 103, 193) !important;
}

.border-left-color--light {
  --border-left-color--light: rgb(255, 255, 255);
  border-left-color: rgb(255, 255, 255) !important;
}

.border-left-color--negative {
  --border-left-color--negative: rgb(206, 22, 22);
  border-left-color: rgb(206, 22, 22) !important;
}

.border-left-color--neutral {
  --border-left-color--neutral: rgb(117, 117, 117);
  border-left-color: rgb(117, 117, 117) !important;
}

.border-left-color--neutral-1 {
  --border-left-color--neutral-1: rgb(51, 51, 51);
  border-left-color: rgb(51, 51, 51) !important;
}

.border-left-color--neutral-2 {
  --border-left-color--neutral-2: rgb(117, 117, 117);
  border-left-color: rgb(117, 117, 117) !important;
}

.border-left-color--neutral-3 {
  --border-left-color--neutral-3: rgb(204, 204, 204);
  border-left-color: rgb(204, 204, 204) !important;
}

.border-left-color--neutral-4 {
  --border-left-color--neutral-4: rgb(230, 230, 230);
  border-left-color: rgb(230, 230, 230) !important;
}

.border-left-color--neutral-5 {
  --border-left-color--neutral-5: rgb(242, 242, 242);
  border-left-color: rgb(242, 242, 242) !important;
}

.border-left-color--positive {
  --border-left-color--positive: rgb(27, 124, 53);
  border-left-color: rgb(27, 124, 53) !important;
}

.border-left-color--none {
  border-left-color: transparent !important;
}

.font-color--caution {
  --font-color--caution: rgb(196, 84, 19);
  color: rgb(196, 84, 19) !important;
}

.font-color--dark {
  --font-color--dark: rgb(26, 26, 26);
  color: rgb(26, 26, 26) !important;
}

.font-color--info {
  --font-color--info: rgb(2, 103, 193);
  color: rgb(2, 103, 193) !important;
}

.font-color--light {
  --font-color--light: rgb(255, 255, 255);
  color: rgb(255, 255, 255) !important;
}

.font-color--negative {
  --font-color--negative: rgb(206, 22, 22);
  color: rgb(206, 22, 22) !important;
}

.font-color--neutral {
  --font-color--neutral: rgb(117, 117, 117);
  color: rgb(117, 117, 117) !important;
}

.font-color--neutral-1 {
  --font-color--neutral-1: rgb(51, 51, 51);
  color: rgb(51, 51, 51) !important;
}

.font-color--neutral-2 {
  --font-color--neutral-2: rgb(117, 117, 117);
  color: rgb(117, 117, 117) !important;
}

.font-color--neutral-3 {
  --font-color--neutral-3: rgb(204, 204, 204);
  color: rgb(204, 204, 204) !important;
}

.font-color--neutral-4 {
  --font-color--neutral-4: rgb(230, 230, 230);
  color: rgb(230, 230, 230) !important;
}

.font-color--neutral-5 {
  --font-color--neutral-5: rgb(242, 242, 242);
  color: rgb(242, 242, 242) !important;
}

.font-color--positive {
  --font-color--positive: rgb(27, 124, 53);
  color: rgb(27, 124, 53) !important;
}

.font-color--primary {
  --font-color--primary: rgb(51, 51, 51);
  color: rgb(51, 51, 51) !important;
}

.hover-font-color--caution:hover {
  --hover-font-color--caution: rgb(196, 84, 19);
  color: rgb(196, 84, 19) !important;
  cursor: pointer;
}

.hover-font-color--dark:hover {
  --hover-font-color--dark: rgb(26, 26, 26);
  color: rgb(26, 26, 26) !important;
  cursor: pointer;
}

.hover-font-color--info:hover {
  --hover-font-color--info: rgb(2, 103, 193);
  color: rgb(2, 103, 193) !important;
  cursor: pointer;
}

.hover-font-color--light:hover {
  --hover-font-color--light: rgb(255, 255, 255);
  color: rgb(255, 255, 255) !important;
  cursor: pointer;
}

.hover-font-color--negative:hover {
  --hover-font-color--negative: rgb(206, 22, 22);
  color: rgb(206, 22, 22) !important;
  cursor: pointer;
}

.hover-font-color--neutral:hover {
  --hover-font-color--neutral: rgb(117, 117, 117);
  color: rgb(117, 117, 117) !important;
  cursor: pointer;
}

.hover-font-color--neutral-1:hover {
  --hover-font-color--neutral-1: rgb(51, 51, 51);
  color: rgb(51, 51, 51) !important;
  cursor: pointer;
}

.hover-font-color--neutral-2:hover {
  --hover-font-color--neutral-2: rgb(117, 117, 117);
  color: rgb(117, 117, 117) !important;
  cursor: pointer;
}

.hover-font-color--neutral-3:hover {
  --hover-font-color--neutral-3: rgb(204, 204, 204);
  color: rgb(204, 204, 204) !important;
  cursor: pointer;
}

.hover-font-color--neutral-4:hover {
  --hover-font-color--neutral-4: rgb(230, 230, 230);
  color: rgb(230, 230, 230) !important;
  cursor: pointer;
}

.hover-font-color--neutral-5:hover {
  --hover-font-color--neutral-5: rgb(242, 242, 242);
  color: rgb(242, 242, 242) !important;
  cursor: pointer;
}

.hover-font-color--positive:hover {
  --hover-font-color--positive: rgb(27, 124, 53);
  color: rgb(27, 124, 53) !important;
  cursor: pointer;
}

.hover-bg-color--caution:hover {
  --hover-bg-color--caution: rgb(196, 84, 19);
  background-color: rgb(196, 84, 19) !important;
  cursor: pointer;
}

.hover-bg-color--dark:hover {
  --hover-bg-color--dark: rgb(26, 26, 26);
  background-color: rgb(26, 26, 26) !important;
  cursor: pointer;
}

.hover-bg-color--info:hover {
  --hover-bg-color--info: rgb(2, 103, 193);
  background-color: rgb(2, 103, 193) !important;
  cursor: pointer;
}

.hover-bg-color--light:hover {
  --hover-bg-color--light: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255) !important;
  cursor: pointer;
}

.hover-bg-color--negative:hover {
  --hover-bg-color--negative: rgb(206, 22, 22);
  background-color: rgb(206, 22, 22) !important;
  cursor: pointer;
}

.hover-bg-color--neutral:hover {
  --hover-bg-color--neutral: rgb(117, 117, 117);
  background-color: rgb(117, 117, 117) !important;
  cursor: pointer;
}

.hover-bg-color--neutral-1:hover {
  --hover-bg-color--neutral-1: rgb(51, 51, 51);
  background-color: rgb(51, 51, 51) !important;
  cursor: pointer;
}

.hover-bg-color--neutral-2:hover {
  --hover-bg-color--neutral-2: rgb(117, 117, 117);
  background-color: rgb(117, 117, 117) !important;
  cursor: pointer;
}

.hover-bg-color--neutral-3:hover {
  --hover-bg-color--neutral-3: rgb(204, 204, 204);
  background-color: rgb(204, 204, 204) !important;
  cursor: pointer;
}

.hover-bg-color--neutral-4:hover {
  --hover-bg-color--neutral-4: rgb(230, 230, 230);
  background-color: rgb(230, 230, 230) !important;
  cursor: pointer;
}

.hover-bg-color--neutral-5:hover {
  --hover-bg-color--neutral-5: rgb(242, 242, 242);
  background-color: rgb(242, 242, 242) !important;
  cursor: pointer;
}

.hover-bg-color--positive:hover {
  --hover-bg-color--positive: rgb(27, 124, 53);
  background-color: rgb(27, 124, 53) !important;
  cursor: pointer;
}

.hover-border-color--caution:hover {
  --hover-border-color--caution: rgb(196, 84, 19);
  border-color: rgb(196, 84, 19) !important;
}

.hover-border-color--dark:hover {
  --hover-border-color--dark: rgb(26, 26, 26);
  border-color: rgb(26, 26, 26) !important;
}

.hover-border-color--info:hover {
  --hover-border-color--info: rgb(2, 103, 193);
  border-color: rgb(2, 103, 193) !important;
}

.hover-border-color--light:hover {
  --hover-border-color--light: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255) !important;
}

.hover-border-color--negative:hover {
  --hover-border-color--negative: rgb(206, 22, 22);
  border-color: rgb(206, 22, 22) !important;
}

.hover-border-color--neutral:hover {
  --hover-border-color--neutral: rgb(117, 117, 117);
  border-color: rgb(117, 117, 117) !important;
}

.hover-border-color--neutral-1:hover {
  --hover-border-color--neutral-1: rgb(51, 51, 51);
  border-color: rgb(51, 51, 51) !important;
}

.hover-border-color--neutral-2:hover {
  --hover-border-color--neutral-2: rgb(117, 117, 117);
  border-color: rgb(117, 117, 117) !important;
}

.hover-border-color--neutral-3:hover {
  --hover-border-color--neutral-3: rgb(204, 204, 204);
  border-color: rgb(204, 204, 204) !important;
}

.hover-border-color--neutral-4:hover {
  --hover-border-color--neutral-4: rgb(230, 230, 230);
  border-color: rgb(230, 230, 230) !important;
}

.hover-border-color--neutral-5:hover {
  --hover-border-color--neutral-5: rgb(242, 242, 242);
  border-color: rgb(242, 242, 242) !important;
}

.hover-border-color--positive:hover {
  --hover-border-color--positive: rgb(27, 124, 53);
  border-color: rgb(27, 124, 53) !important;
}

.hover-border-top-color--caution:hover {
  --hover-border-top-color--caution: rgb(196, 84, 19);
  border-top-color: rgb(196, 84, 19) !important;
}

.hover-border-top-color--dark:hover {
  --hover-border-top-color--dark: rgb(26, 26, 26);
  border-top-color: rgb(26, 26, 26) !important;
}

.hover-border-top-color--info:hover {
  --hover-border-top-color--info: rgb(2, 103, 193);
  border-top-color: rgb(2, 103, 193) !important;
}

.hover-border-top-color--light:hover {
  --hover-border-top-color--light: rgb(255, 255, 255);
  border-top-color: rgb(255, 255, 255) !important;
}

.hover-border-top-color--negative:hover {
  --hover-border-top-color--negative: rgb(206, 22, 22);
  border-top-color: rgb(206, 22, 22) !important;
}

.hover-border-top-color--neutral:hover {
  --hover-border-top-color--neutral: rgb(117, 117, 117);
  border-top-color: rgb(117, 117, 117) !important;
}

.hover-border-top-color--neutral-1:hover {
  --hover-border-top-color--neutral-1: rgb(51, 51, 51);
  border-top-color: rgb(51, 51, 51) !important;
}

.hover-border-top-color--neutral-2:hover {
  --hover-border-top-color--neutral-2: rgb(117, 117, 117);
  border-top-color: rgb(117, 117, 117) !important;
}

.hover-border-top-color--neutral-3:hover {
  --hover-border-top-color--neutral-3: rgb(204, 204, 204);
  border-top-color: rgb(204, 204, 204) !important;
}

.hover-border-top-color--neutral-4:hover {
  --hover-border-top-color--neutral-4: rgb(230, 230, 230);
  border-top-color: rgb(230, 230, 230) !important;
}

.hover-border-top-color--neutral-5:hover {
  --hover-border-top-color--neutral-5: rgb(242, 242, 242);
  border-top-color: rgb(242, 242, 242) !important;
}

.hover-border-top-color--positive:hover {
  --hover-border-top-color--positive: rgb(27, 124, 53);
  border-top-color: rgb(27, 124, 53) !important;
}

.hover-border-right-color--caution:hover {
  --hover-border-right-color--caution: rgb(196, 84, 19);
  border-right-color: rgb(196, 84, 19) !important;
}

.hover-border-right-color--dark:hover {
  --hover-border-right-color--dark: rgb(26, 26, 26);
  border-right-color: rgb(26, 26, 26) !important;
}

.hover-border-right-color--info:hover {
  --hover-border-right-color--info: rgb(2, 103, 193);
  border-right-color: rgb(2, 103, 193) !important;
}

.hover-border-right-color--light:hover {
  --hover-border-right-color--light: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255) !important;
}

.hover-border-right-color--negative:hover {
  --hover-border-right-color--negative: rgb(206, 22, 22);
  border-right-color: rgb(206, 22, 22) !important;
}

.hover-border-right-color--neutral:hover {
  --hover-border-right-color--neutral: rgb(117, 117, 117);
  border-right-color: rgb(117, 117, 117) !important;
}

.hover-border-right-color--neutral-1:hover {
  --hover-border-right-color--neutral-1: rgb(51, 51, 51);
  border-right-color: rgb(51, 51, 51) !important;
}

.hover-border-right-color--neutral-2:hover {
  --hover-border-right-color--neutral-2: rgb(117, 117, 117);
  border-right-color: rgb(117, 117, 117) !important;
}

.hover-border-right-color--neutral-3:hover {
  --hover-border-right-color--neutral-3: rgb(204, 204, 204);
  border-right-color: rgb(204, 204, 204) !important;
}

.hover-border-right-color--neutral-4:hover {
  --hover-border-right-color--neutral-4: rgb(230, 230, 230);
  border-right-color: rgb(230, 230, 230) !important;
}

.hover-border-right-color--neutral-5:hover {
  --hover-border-right-color--neutral-5: rgb(242, 242, 242);
  border-right-color: rgb(242, 242, 242) !important;
}

.hover-border-right-color--positive:hover {
  --hover-border-right-color--positive: rgb(27, 124, 53);
  border-right-color: rgb(27, 124, 53) !important;
}

.hover-border-bottom-color--caution:hover {
  --hover-border-bottom-color--caution: rgb(196, 84, 19);
  border-bottom-color: rgb(196, 84, 19) !important;
}

.hover-border-bottom-color--dark:hover {
  --hover-border-bottom-color--dark: rgb(26, 26, 26);
  border-bottom-color: rgb(26, 26, 26) !important;
}

.hover-border-bottom-color--info:hover {
  --hover-border-bottom-color--info: rgb(2, 103, 193);
  border-bottom-color: rgb(2, 103, 193) !important;
}

.hover-border-bottom-color--light:hover {
  --hover-border-bottom-color--light: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255) !important;
}

.hover-border-bottom-color--negative:hover {
  --hover-border-bottom-color--negative: rgb(206, 22, 22);
  border-bottom-color: rgb(206, 22, 22) !important;
}

.hover-border-bottom-color--neutral:hover {
  --hover-border-bottom-color--neutral: rgb(117, 117, 117);
  border-bottom-color: rgb(117, 117, 117) !important;
}

.hover-border-bottom-color--neutral-1:hover {
  --hover-border-bottom-color--neutral-1: rgb(51, 51, 51);
  border-bottom-color: rgb(51, 51, 51) !important;
}

.hover-border-bottom-color--neutral-2:hover {
  --hover-border-bottom-color--neutral-2: rgb(117, 117, 117);
  border-bottom-color: rgb(117, 117, 117) !important;
}

.hover-border-bottom-color--neutral-3:hover {
  --hover-border-bottom-color--neutral-3: rgb(204, 204, 204);
  border-bottom-color: rgb(204, 204, 204) !important;
}

.hover-border-bottom-color--neutral-4:hover {
  --hover-border-bottom-color--neutral-4: rgb(230, 230, 230);
  border-bottom-color: rgb(230, 230, 230) !important;
}

.hover-border-bottom-color--neutral-5:hover {
  --hover-border-bottom-color--neutral-5: rgb(242, 242, 242);
  border-bottom-color: rgb(242, 242, 242) !important;
}

.hover-border-bottom-color--positive:hover {
  --hover-border-bottom-color--positive: rgb(27, 124, 53);
  border-bottom-color: rgb(27, 124, 53) !important;
}

.hover-border-left-color--caution:hover {
  --hover-border-left-color--caution: rgb(196, 84, 19);
  border-left-color: rgb(196, 84, 19) !important;
}

.hover-border-left-color--dark:hover {
  --hover-border-left-color--dark: rgb(26, 26, 26);
  border-left-color: rgb(26, 26, 26) !important;
}

.hover-border-left-color--info:hover {
  --hover-border-left-color--info: rgb(2, 103, 193);
  border-left-color: rgb(2, 103, 193) !important;
}

.hover-border-left-color--light:hover {
  --hover-border-left-color--light: rgb(255, 255, 255);
  border-left-color: rgb(255, 255, 255) !important;
}

.hover-border-left-color--negative:hover {
  --hover-border-left-color--negative: rgb(206, 22, 22);
  border-left-color: rgb(206, 22, 22) !important;
}

.hover-border-left-color--neutral:hover {
  --hover-border-left-color--neutral: rgb(117, 117, 117);
  border-left-color: rgb(117, 117, 117) !important;
}

.hover-border-left-color--neutral-1:hover {
  --hover-border-left-color--neutral-1: rgb(51, 51, 51);
  border-left-color: rgb(51, 51, 51) !important;
}

.hover-border-left-color--neutral-2:hover {
  --hover-border-left-color--neutral-2: rgb(117, 117, 117);
  border-left-color: rgb(117, 117, 117) !important;
}

.hover-border-left-color--neutral-3:hover {
  --hover-border-left-color--neutral-3: rgb(204, 204, 204);
  border-left-color: rgb(204, 204, 204) !important;
}

.hover-border-left-color--neutral-4:hover {
  --hover-border-left-color--neutral-4: rgb(230, 230, 230);
  border-left-color: rgb(230, 230, 230) !important;
}

.hover-border-left-color--neutral-5:hover {
  --hover-border-left-color--neutral-5: rgb(242, 242, 242);
  border-left-color: rgb(242, 242, 242) !important;
}

.hover-border-left-color--positive:hover {
  --hover-border-left-color--positive: rgb(27, 124, 53);
  border-left-color: rgb(27, 124, 53) !important;
}

.font-style--italic {
  font-style: italic !important;
}

.font-weight--100 {
  font-weight: 100 !important;
}

.font-weight--light,
.font-weight--200 {
  font-weight: 200 !important;
}

.font-weight--300 {
  font-weight: 300 !important;
}

.font-weight--normal,
.font-weight--400 {
  font-weight: 400 !important;
}

.font-weight--medium,
.font-weight--500 {
  font-weight: 500 !important;
}

.font-weight--600 {
  font-weight: 600 !important;
}

.font-weight--bold,
.font-weight--700 {
  font-weight: 700 !important;
}

.font-size--xs {
  font-size: 1.4rem !important;
}

.font-size--sm {
  font-size: 1.6rem !important;
}

.font-size--md {
  font-size: 2rem !important;
}

.font-size--lg {
  font-size: 3rem !important;
}

.font-size--xl {
  font-size: 4rem !important;
}