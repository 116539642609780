// Color variables

$color-caution: $colorBrandedCaution !default;
$color-dark: $colorPlatformGray900 !default;
$color-info: $colorBrandedInfo !default;
$color-light: $colorPlatformWhite !default;
$color-negative: $colorBrandedError !default;
$color-neutral: $colorPlatformGray500 !default;
$color-neutral-1: $colorPlatformGray800 !default;
$color-neutral-2: $colorPlatformGray500 !default;
$color-neutral-3: $colorPlatformGray200 !default;
$color-neutral-4: $colorPlatformGray100 !default;
$color-neutral-5: $colorPlatformGray50 !default;
$color-positive: $colorBrandedSuccess !default;
$color-map: (
  caution: $color-caution,
  dark: $color-dark,
  info: $color-info,
  light: $color-light,
  negative: $color-negative,
  neutral: $color-neutral,
  neutral-1: $color-neutral-1,
  neutral-2: $color-neutral-2,
  neutral-3: $color-neutral-3,
  neutral-4: $color-neutral-4,
  neutral-5: $color-neutral-5,
  positive: $color-positive
);


//  BACKGROUND
// =============================================================================
@each $color-key, $color-value in $color-map {
  .bg-color--#{$color-key} {
    --bg-color--#{$color-key}: #{$color-value};

    background-color: $color-value !important;
  }
}

.bg-color--none {
  background-color: transparent !important;
}


// BORDERS
// =============================================================================
// Border All
@each $color-key, $color-value in $color-map {
  .border-color--#{$color-key} {
    --border-color--#{$color-key}: #{$color-value};

    border-color: $color-value !important;
  }
}

.border-color--none {
	border-color: transparent !important;
}

// Border Top
@each $color-key, $color-value in $color-map {
  .border-top-color--#{$color-key} {
    --border-top-color--#{$color-key}: #{$color-value};

    border-top-color: $color-value !important;
  }
}

.border-top-color--none {
	border-top-color: transparent !important;
}

// Border Right
@each $color-key, $color-value in $color-map {
  .border-right-color--#{$color-key} {
    --border-right-color--#{$color-key}: #{$color-value};

    border-right-color: $color-value !important;
  }
}

.border-right-color--none {
	border-right-color: transparent !important;
}

// Border Bottom
@each $color-key, $color-value in $color-map {
  .border-bottom-color--#{$color-key} {
    --border-bottom-color--#{$color-key}: #{$color-value};

    border-bottom-color: $color-value !important;
  }
}

.border-bottom-color--none {
	border-bottom-color: transparent !important;
}

// Border Left
@each $color-key, $color-value in $color-map {
  .border-left-color--#{$color-key} {
    --border-left-color--#{$color-key}: #{$color-value};

    border-left-color: $color-value !important;
  }
}

.border-left-color--none {
	border-left-color: transparent !important;
}


// FONT
// =============================================================================
@each $color-key, $color-value in $color-map {
  .font-color--#{$color-key} {
    --font-color--#{$color-key}: #{$color-value};

    color: $color-value !important;
  }
}

.font-color--primary {
  --font-color--primary: #{$color-neutral-1};

  color: $color-neutral-1 !important;
}


// HOVER
// =============================================================================
// Hover Font Color
@each $color-key, $color-value in $color-map {
  .hover-font-color--#{$color-key} {
    &:hover {
      --hover-font-color--#{$color-key}: #{$color-value};

      color: $color-value !important;
      cursor: pointer;
    }
  }
}

// Hover Background Color
@each $color-key, $color-value in $color-map {
  .hover-bg-color--#{$color-key} {
    &:hover {
      --hover-bg-color--#{$color-key}: #{$color-value};

      background-color: $color-value !important;
      cursor: pointer;
    }
  }
}

// Hover Border Color
@each $color-key, $color-value in $color-map {
  .hover-border-color--#{$color-key} {
    &:hover {
      --hover-border-color--#{$color-key}: #{$color-value};

      border-color: $color-value !important;
    }
  }
}

// Hover Border Top Color
@each $color-key, $color-value in $color-map {
  .hover-border-top-color--#{$color-key} {
    &:hover {
      --hover-border-top-color--#{$color-key}: #{$color-value};

      border-top-color: $color-value !important;
    }
  }
}

// Hover Border Right Color
@each $color-key, $color-value in $color-map {
  .hover-border-right-color--#{$color-key} {
    &:hover {
      --hover-border-right-color--#{$color-key}: #{$color-value};

      border-right-color: $color-value !important;
    }
  }
}

// Hover Border Bottom Color
@each $color-key, $color-value in $color-map {
  .hover-border-bottom-color--#{$color-key} {
    &:hover {
      --hover-border-bottom-color--#{$color-key}: #{$color-value};

      border-bottom-color: $color-value !important;
    }
  }
}

// Hover Border Left Color
@each $color-key, $color-value in $color-map {
  .hover-border-left-color--#{$color-key} {
    &:hover {
      --hover-border-left-color--#{$color-key}: #{$color-value};

      border-left-color: $color-value !important;
    }
  }
}
