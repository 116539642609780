// Variables
// =============================================================================

// Text variables
$date-month-color: $colorPlatformGray500 !default;
$date-day-color: $colorPlatformGray500 !default;
$date-year-color: $colorPlatformGray500 !default;
$date-linear-color: $colorPlatformGray500 !default;

// Example Markup
// =============================================================================
//	<div class="iris-date iris-date--stacked-full">
//		<span class="iris-date__month">Oct</span>
//		<span class="iris-date__day">28</span>
//		<span class="iris-date__year">2017</span>
//	</div>


// Base Styling
// =============================================================================
.iris-date {
	text-align: center;

	.iris-date__month {
		text-transform: uppercase;
	}
}

// Variants:
// =============================================================================
.iris-date {
	// Variant: linear
	&--linear {
		color: $date-linear-color;
		font-size: 1.2rem;
	}

	// Variant: Stacked Full
	&--stacked-full {
		.iris-date__month {
			display: block;
			font-size: 1.1rem;
		}

		.iris-date__day {
			display: block;
			font-size: 1.8rem;
			font-weight: 700;
		}

		.iris-date__year {
			display: block;
			font-size: 0.9rem;
		}
	}

	// Variant: Stacked Month Year
	&--stacked-month-year {
		.iris-date__month {
			display: block;
			font-size: 1.4rem;
			font-weight: 700;
		}

		.iris-date__day {
			display: none;
		}

		.iris-date__year {
			display: block;
			font-size: 1.4rem;
		}
	}

	&--stacked-full,
	&--stacked-month-year {
		.iris-date__month {
			color: $date-month-color;
		}

		.iris-date__day {
			color: $date-day-color;
		}

		.iris-date__year {
			color: $date-year-color;
		}
	}
}

// DEPRECATED 0.12.0
.iris-date__full-date {
	// Putting an aria-label on an element who has all aria-hidden elements
	// will cause the aria-label to not be read. Instead we need a
	// screenreader only element.
	@include screenreader-only;
}
