// Default Variables
// =============================================================================

// Border variables
$radio-border-color-default: $colorPlatformGray500 !default;
$radio-border-color-selected: $colorPlatformGray500 !default;
$radio-border-color-hover: $colorPlatformGray800 !default;
$radio-border-color-focus: $colorBrandedInfo !default;
$radio-border-width-default: 1px !default;

// Box shadow variables
$radio-box-shadow-focus: $colorBrandedInfo !default;
$radio-box-shadow-disabled: none !default;

// Background variables
$radio-background-default: $colorPlatformWhite !default;
$radio-background-selected: $colorBrandedInfo !default;
$radio-description: $colorPlatformGray500 !default;

// Dimension variables
$radio-dimension-default: 24px !default;


// Platform Variables and Mixin Imports
// =============================================================================
@import './partials/_radio.android';


// Example HTML markup
// =============================================================================
// <div class="iris-radio">
//     <input class="iris-radio__input" type="radio" id="example1" name="firstExample" aria-describedby="radio_example_1_description" />
//     <label class="iris-radio__label" for="example1">
//	       <span class="iris-radio__content">Enable Email Alerts</span>
//	   </label>
//     <div class="iris-radio__extended-content" >
//	        <span class="iris-radio__description" id="radio_example_1_description">Receive account information by text command. A list of commands will be sent to this number.</span>
//     </div>
// </div>


// Styles
// =============================================================================
// Hide the input and trigger a state change to the label based on checked status.
.iris-radio__input {
	// Set the opacity, position, and size instead of display: none to receive focus
	height: 1px;
	opacity: 0;
	position: absolute;
	width: 1px;

	&:checked ~ .iris-radio__label::before {
		border-color: $radio-border-color-selected;
	}

	&:checked ~ .iris-radio__label::after {
		transform: translateY(-50%) scale(1, 1);
	}

	&:focus ~ .iris-radio__label::before {
		border-color: $radio-border-color-focus;
		box-shadow: 0 0 4px 2px $radio-box-shadow-focus;
	}
}

// Create a styled radio button as a psuedo element.
.iris-radio__label {
	align-items: center;
	cursor: pointer;
	display: flex;
	font-size: 1.4rem;
	font-weight: 600;
	position: relative;

	&::before {
		background: $radio-background-default;
		border: $radio-border-width-default solid $radio-border-color-default;
		border-radius: 100%;
		content: '';
		display: block;
		flex-shrink: 0;
		height: $radio-dimension-default;
		transition: 200ms all linear;
		width: $radio-dimension-default;
	}

	&::after {
		background: $radio-background-selected;
		border-radius: 100%;
		content: '';
		display: block;
		height: calc($radio-dimension-default / 2);
		left: calc($radio-dimension-default / 4);
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%) scale(0, 0);
		transition: transform 0.2s cubic-bezier(0.8, 0.1, 0.2, 1.5);
		width: calc($radio-dimension-default / 2);
	}

	&:hover::before {
		border-color: $radio-border-color-hover;
	}
}

.iris-radio__content {
	margin-left: 10px;
}

.iris-radio__extended-content {
	display: block;
	margin-left: 34px;
	margin-top: 5px;
}

.iris-radio__description {
	color: $radio-description;
	display: inline-block;
	font-size: 1.4rem;
}

// States
// =============================================================================
.iris-radio {
	&--disabled .iris-radio__input,
	.iris-radio__input:disabled {
		 ~ .iris-radio__label {
			cursor: not-allowed;
			opacity: 0.5;
			pointer-events: none;

			&:hover::before {
				border-color: $radio-border-color-default;
			}
		}

		&:focus ~ .iris-radio__label::before {
			border-color: $radio-border-color-default;
			box-shadow: $radio-box-shadow-disabled;
		}
	}
}
