
// Variables
// =============================================================================

// Color variables
$tooltip-background-color: $colorPlatformGray800 !default;
$tooltip-text-color-default: $colorPlatformWhite !default;
$tooltip-border-color: $colorPlatformGray800 !default;

// Text variables
$tooltip-font-size: 1.4rem !default;

// Width variables
$tooltip-width-small: $container-width-small !default;
$tooltip-width-medium: $container-width-medium !default;
$tooltip-width-large: $container-width-large !default;

// Example HTML markup (programmatically generated so don't copy!)
// =============================================================================
// <span class="iris-tooltip" role="tooltip" x-indicator="center" x-size="auto" id="iris_tooltip_1512675375023" x-placement="bottom">
// <span class="iris-tooltip__content">
// I'm a tooltip!
// </span></span>

.iris-tooltip {
	background: $tooltip-background-color;
	border: 1px solid $tooltip-border-color;
	border-radius: 5px;
	box-shadow: 0 20px 50px -5px rgb(0 0 0 / 30%);
	color: $tooltip-text-color-default;
	font-size: $tooltip-font-size;
	margin: 1rem;
	max-width: 95%;
	padding: 1rem;
	z-index: z-index('tooltip');
}

.iris-tooltip__content {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.iris-tooltip--expand-start {
	display: block;
	opacity: 0;
}

.iris-tooltip--expanding {
	display: block;
	opacity: 1;
	transition: opacity 0.2s ease-in-out;
}

.iris-tooltip--expanded {
	display: block;
}

.iris-tooltip--collapse-start {
	display: block;
}

.iris-tooltip--collapsing {
	display: block;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
}

.iris-tooltip--collapsed {
	display: none;
}

.iris-tooltip {
	position: relative;

	&::before {
		background: $tooltip-background-color;
		border: 1px solid $tooltip-background-color;
		content: '';
		display: block;
		height: 9px;
		position: absolute;
		transform: translateX(50%) rotate(45deg);
		width: 9px;
	}

	&[x-indicator='none']::before {
		display: none;
	}

	&[x-placement^='top'] {
		margin-left: 0;
		margin-right: 0;

		&::before {
			border-bottom: 1px solid $tooltip-border-color;
			border-right: 1px solid $tooltip-border-color;
			bottom: -5px;
			right: 50%;
		}

		&[x-indicator^='start']::before {
			left: 10px;
			right: auto;
		}

		&[x-indicator^='end'] {
			text-align: right;

			&::before {
				right: 10px;
			}
		}
	}

	&[x-placement^='right'] {
		margin-bottom: 0;
		margin-top: 0;

		&::before {
			border-bottom: 1px solid $tooltip-border-color;
			border-left: 1px solid $tooltip-border-color;
			bottom: 50%;
			left: -5px;
			margin-bottom: -5px;
			transform: rotate(45deg);
		}

		&[x-indicator^='start']::before {
			bottom: auto;
			top: 10px;
		}

		&[x-indicator^='end']::before {
			bottom: 10px;
		}
	}

	&[x-placement^='bottom'] {
		margin-left: 0;
		margin-right: 0;

		&::before {
			border-left: 1px solid $tooltip-border-color;
			border-top: 1px solid $tooltip-border-color;
			right: 50%;
			top: -5px;
		}

		&[x-indicator^='start']::before {
			left: 10px;
			right: auto;
		}

		&[x-indicator^='end'] {
			text-align: right;

			&::before {
				right: 10px;
			}
		}
	}

	&[x-placement^='left'] {
		margin-bottom: 0;
		margin-top: 0;

		&::before {
			border-right: 1px solid $tooltip-border-color;
			border-top: 1px solid $tooltip-border-color;
			bottom: 50%;
			margin-bottom: -5px;
			right: -5px;
			transform: rotate(45deg);
		}

		&[x-indicator^='start']::before {
			bottom: auto;
			top: 10px;
		}

		&[x-indicator^='end']::before {
			bottom: 10px;
		}
	}
}

// Decorators
// =============================================================================
.iris-tooltip {
	&:not([x-size='auto']) .iris-tooltip__content {
		white-space: normal;
	}

	&[x-size='small'] {
		width: $tooltip-width-small;
	}

	&[x-size='medium'] {
		width: $tooltip-width-medium;
	}

	&[x-size='large'] {
		width: $tooltip-width-large;
	}
}
