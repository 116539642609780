// Variables
// =============================================================================
$chevron-color-default: $colorPlatformGray500;

// Styling
// =============================================================================
.iris-chevron,
.iris-chevron--down {
	display: block;
	flex-shrink: 0;
	position: relative;
	width: 1.6rem;

	&::after,
	&::before {
		border-bottom: solid 2px $chevron-color-default;
		content: '';
		height: 0;
		position: absolute;
		top: 0;
		transition-duration: 0.25s;
		width: 1rem;
	}

	&::before {
		left: 0;
		transform: rotate(225deg);
		transition-timing-function: ease-in;
	}

	&::after {
		right: 0;
		transform: rotate(-225deg);
		transition-timing-function: ease-in;
	}
}

.iris-chevron--up {
	&::before {
		transform: rotate(135deg);
		transition-timing-function: ease-out;
	}

	&::after {
		transform: rotate(-135deg);
		transition-timing-function: ease-out;
	}
}

.iris-chevron--right,
.iris-chevron--left {
	border-bottom: 2px solid $chevron-color-default;
	border-left: 2px solid $chevron-color-default;
	display: inline-block;
	height: 1.4rem;
	width: 1.4rem;

	&::before,
	&::after {
		content: none;
	}

	&[data-size='xs'] {
		height: 1rem;
		width: 1rem;
	}

	&[data-size='sm'] {
		height: 1.4rem;
		width: 1.4rem;
	}

	&[data-size='md'] {
		height: 1.6rem;
		width: 1.6rem;
	}

	&[data-size='lg'] {
		height: 2rem;
		width: 2rem;
	}

	&[data-size='xl'] {
		height: 2.4rem;
		width: 2.4rem;
	}
}

.iris-chevron--right {
	transform: rotate(225deg);
}

.iris-chevron--left {
	transform: rotate(45deg);
}
