// Variables
// =============================================================================

// Border variables
$content-header-border-default: $colorPlatformGray100 !default;

// Font variables
$content-header-item-weight: 600 !default;
$content-header-item-color: $colorPlatformGray500 !default;
$content-header-item-color-hover: darken($colorPlatformGray500, 30%) !default;

// Color Variables
$content-header-sorter-color-default: transparentize($colorPlatformGray500, 0.5) !default;
$content-header-sorter-color-active: $colorPlatformGray800 !default;


// Example HTML markup
// =============================================================================
// <div class="iris-content-header">
//     <div class="iris-content-header__item">Content</div>
// </div>


// Base styling
// =============================================================================
.iris-content-header {
	border-bottom: 2px solid $content-header-border-default;
	padding: 10px 0;
	width: 100%;
}

.iris-content-header__item {
	align-items: center;
	color: $content-header-item-color;
	display: inline-flex;
	font-size: 1.2rem;
	font-weight: $content-header-item-weight;
	text-transform: uppercase;
	transition: 200ms all linear;
}

.iris-content-header__title {
	margin: 0;
}

// For accessibility purposes, we are using a button for a sortable header.
// This will reset all styles for buttons from the browser to match the design.

/* stylelint-disable comment-empty-line-before, selector-no-qualifying-type */
button.iris-content-header__item {
	background: none;
	border: 0;
	line-height: normal;
	overflow: visible;
	padding: 0;
	user-select: none;

	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}

/* stylelint-enable */


// Decorators
// =============================================================================
.iris-content-header__item {
	// All of the sortable markup and styling is gated behind the sortable boson
	// This component requires the boson as well as additional markup to work
	&[data-sortable] {
		cursor: pointer;

		&:not(.iris-content-header__item--ascending, .iris-content-header__item--descending) {
			&:hover {
				color: $content-header-item-color-hover;

				.iris-content-header__sorter::before {
					border-bottom-color: $content-header-item-color-hover;
				}

				.iris-content-header__sorter::after {
					border-top-color: $content-header-item-color-hover;
				}
			}
		}

		.iris-content-header__sorter {
			display: inline-flex;
			flex-direction: column;
			padding-left: 5px;

			&::before,
			&::after {
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				content: '';
				display: block;
				transition: 300ms all linear;
			}

			// Top sortable arrow
			&::before {
				border-bottom: 7px solid $content-header-sorter-color-default;
				margin-bottom: 2px;
			}

			// Bottom sortable arrow
			&::after {
				border-top: 7px solid $content-header-sorter-color-default;
			}
		}

		// Update the color of the label as well as the sort triangle color.
		&.iris-content-header__item--ascending {
			color: $content-header-sorter-color-active;

			.iris-content-header__sorter::before {
				border-bottom-color: $content-header-sorter-color-active;
			}
		}

		&.iris-content-header__item--descending {
			color: $content-header-sorter-color-active;

			.iris-content-header__sorter::after {
				border-top-color: $content-header-sorter-color-active;
			}
		}
	}
}
